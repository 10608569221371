import _get from 'lodash/get';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import appActionTypes from './actionTypes';
import urls from '../../../util/urls';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { window } from '../../../util/globals';

// NOTE: typically, we don't want cross-pollination of action files.
// App startup is an exception; don't repeat this elsewhere.
import { ProcessAuthArgs } from '../auth/actions';
import { authActions, authSelectors } from '../auth';
import { configActions } from '../config';
import { accountActions, accountSelectors } from '../account';
import { projectPickerActions, projectPickerSelectors } from '../projectPicker';
import {
  projectCohortPickerActions,
  projectCohortPickerSelectors,
} from '../projectCohortPicker';
import { evaluatedPolicyActions } from '../evaluatedPolicy';
import { featuresActions } from '../features';
import { userPatientsActions } from '../userPatients';

import { Dispatch } from 'redux';
import { Feature } from '../features/actions';
import { Account } from '../../../common/types/Accounts';

const { processAuth } = authActions;
const { loadConfig } = configActions;
const { fetchAccounts, setActiveAccount } = accountActions;
const { fetchEvaluatedPolicy } = evaluatedPolicyActions;
const { fetchFeatures, fetchUnauthFeatures, toggleFeatures } = featuresActions;
const { hydrateActiveProject, fetchProjects } = projectPickerActions;
const { hydrateActiveProjectCohort, fetchProjectCohorts } =
  projectCohortPickerActions;
const { fetchUserPatients } = userPatientsActions;

async function initializeFeatures(dispatch: Dispatch) {
  await dispatch(fetchEvaluatedPolicy());
  await dispatch(fetchFeatures());
}

function initializeApp({ skipCookieProcessing = false }: ProcessAuthArgs = {}) {
  return async function (dispatch: Dispatch, getState: () => any) {
    dispatch(initializeAppStart());
    dispatch(loadConfig());
    await dispatch(processAuth({ skipCookieProcessing }));
    const originalSearch = _get(getState(), 'router.location.search');
    const destinationEncoded = queryString.parse(originalSearch)
      .destination as string;
    const destination = destinationEncoded
      ? decodeURIComponent(destinationEncoded)
      : null;
    if (authSelectors.isAuthenticated(getState())) {
      await dispatch(fetchAccounts());
      const accounts = accountSelectors.selectAccounts(getState());
      if (accounts.length > 0) {
        dispatch(accountActions.hydrateActiveAccount());
        const hydratedActiveAccountId = accountSelectors.selectActiveAccountId(
          getState(),
        );

        const hyrdatedAccount =
          accounts.find(acct => acct.id === hydratedActiveAccountId) ||
          accounts[0];
        const acctQueryParam = queryString.parse(window.location.search)
          .account as string;
        const foundAcct: Partial<Account> = acctQueryParam
          ? accounts.find(acct => acct.id === acctQueryParam) || hyrdatedAccount
          : hyrdatedAccount;

        dispatch(setActiveAccount(foundAcct.id));
        await Promise.all([
          initializeFeatures(dispatch),
          dispatch(fetchUserPatients(foundAcct.id)),
        ]);

        const features = getFeatures(window.location.search);
        if (features) {
          dispatch(toggleFeatures(features));
        }

        if (!projectPickerSelectors.selectActiveProjectId(getState())) {
          const projectQueryParam = queryString.parse(window.location.search)
            .projectId as string;
          await dispatch(fetchProjects({ params: { pageSize: 1000 } }));
          dispatch(hydrateActiveProject(projectQueryParam));
        }

        if (
          !projectCohortPickerSelectors.selectActiveProjectCohortId(getState())
        ) {
          const cohortQueryParam = queryString.parse(window.location.search)
            .cohortId as string;
          const activeProjectId = projectPickerSelectors.selectActiveProjectId(
            getState(),
          );
          await dispatch(
            fetchProjectCohorts({
              params: {
                projectId: activeProjectId,
                fields: 'name,id,description,ownerProject',
                pageSize: 1000,
              },
            }),
          );
          dispatch(hydrateActiveProjectCohort(cohortQueryParam));
        }
      } else if (
        // These two lines allow new users to create PHC accounts in dev only
        // rather than being redirected to the invitations page:
        '/phc/account/accounts/create/force' !== window.location.pathname &&
        '/phc/account/accounts/create/force' !== destination &&
        // Not sure these two lines are needed because there is no such route
        // in the phc-ui, but since this is a shared module I'm leaving them in place:
        '/phc/app/project-tasks/accept-invite' !== window.location.pathname &&
        '/phc/app/project-tasks/accept-invite' !== destination &&
        // These two lines allow user to click on an invitation URL that has
        // query params for the invite and automatically accept it without
        // being redirected to the invitations page with no query params:
        '/phc/account/invitations' !== window.location.pathname &&
        '/phc/account/invitations' !== destination
      ) {
        // user does not have an account, automatically send him to invitations
        dispatch(push(urls.app.invitations()));
      }
    } else {
      await dispatch(fetchUnauthFeatures());
    }
    if (destination) {
      const originalParams = queryString.parse(originalSearch);

      // filter for relevant params
      const targetParams = _pick(originalParams, [
        'inviteId',
        'activeSeq',
        'summary',
        'fileId',
      ]);
      const query = _isEmpty(targetParams)
        ? ''
        : `?${queryString.stringify(targetParams)}`;
      const targetUrl = destination.match(/^\/phc/)
        ? destination.substr(4)
        : destination;

      dispatch(push(`${targetUrl}${query}`));
    }
    dispatch(initializeAppEnd());
  };
}

function initializeAppStart() {
  return {
    type: appActionTypes.INITIALIZE_APP_START,
    app: { initializing: true, init: false },
  };
}

function initializeAppEnd() {
  return {
    type: appActionTypes.INITIALIZE_APP_END,
    app: { initializing: false, init: true },
  };
}

const normalizeBool = (val: string | boolean): boolean => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'true') return true;
    if (val.toLowerCase() === 'false') return false;
  }
  return val as boolean;
};

export function getFeatures(search: any) {
  const featureStr = queryString.parse(search).features as string;
  if (featureStr) {
    const features = _map(featureStr.split(','), val => {
      const kv = val.split('$');
      const f: Feature = { feature: kv[0] };
      if (kv[1]) f.value = normalizeBool(kv[1]);
      return f;
    });
    return features.length > 0 ? features : null;
  }
  return null;
}

export default {
  getFeatures,
  initializeApp,
  initializeAppStart,
  initializeAppEnd,
};
