import _get from 'lodash/get';
import featuresActionTypes from './actionTypes';
import {
  createAsyncRequestReducer,
  AsyncRequestReducerOptions,
  FetchState,
} from '../../util/fetchReducers';
import actions from './actions';
import { combineReducers } from 'redux';

export interface FeaturesState extends FetchState {
  response: {
    [key: string]: boolean;
  };
}

type ToggleFeatureAction = ReturnType<typeof actions.toggleFeature>;
type ToggleFeaturesAction = ReturnType<typeof actions.toggleFeatures>;

const options: AsyncRequestReducerOptions<FeaturesState> = {
  reducers: {
    [featuresActionTypes.TOGGLE_FEATURE]: (
      state,
      action: ToggleFeatureAction,
    ) => {
      const features = state.response || {};
      if (Object.prototype.hasOwnProperty.call(features, action.feature)) {
        const newValue =
          typeof action.value !== 'undefined'
            ? action.value
            : !state.response[action.feature];
        return {
          ...state,
          response: {
            ...state.response,
            [action.feature]: newValue,
          },
        };
      } else {
        return state;
      }
    },
    [featuresActionTypes.TOGGLE_FEATURES]: (
      state,
      action: ToggleFeaturesAction,
    ) => {
      const features = _get(state, 'response', {});
      if (features) {
        const newFeatures: FeaturesState['response'] = {};
        action.features.forEach(f => {
          newFeatures[f.feature] =
            typeof f.value !== 'undefined'
              ? f.value
              : !state.response[f.feature];
        });
        return {
          ...state,
          response: {
            ...state.response,
            ...newFeatures,
          },
        };
      } else {
        return state;
      }
    },
  },
};

const reducers = {
  fetchFeatures: createAsyncRequestReducer(
    featuresActionTypes.FETCH_FEATURES,
    options,
  ),
  fetchUnauthFeatures: createAsyncRequestReducer(
    featuresActionTypes.FETCH_UNAUTH_FEATURES,
    options,
  ),
};

export default combineReducers(reducers);
