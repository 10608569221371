import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '@lifeomic/chroma-react/components/Box';
import useInitialization from '../hooks/useInitialization';
import LoadingView from './pages/Loading';
import Switcher from './pages/Switcher';
import NotFound from './pages/NotFound';

const App: React.FC = () => {
  const isInitializing = useInitialization();

  if (isInitializing) return <LoadingView />;
  return (
    <Box align="center" justify="center" direction="column" fullWidth fullHeight>
      <Switch>
        <Route path="/" exact component={Switcher} />

        <Route path="*" component={NotFound} />
      </Switch>
    </Box>
  );
};

export default App;
