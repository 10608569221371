import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Account } from '@lifeomic/phc-web-toolkit/dist/common/types/Accounts';
import { makeStyles } from '@lifeomic/chroma-react/styles';
import { Text } from '@lifeomic/chroma-react/components/Text';
import {
  IconTile,
  IconTileContent,
  IconTileHero,
} from '@lifeomic/chroma-react/components/IconTile';
import { Pill } from '@lifeomic/chroma-react/components/Pill';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { getTestProps } from '../../../../../util/testProps';
import { Button } from '@lifeomic/chroma-react/components/Button';
import * as phc from '../../../../../static/symbol/logos/symbol/reverse/symbol-reverse.svg';
import ProductsModal from './ProductsModal';

const testIds = {
  iconTile: (accountId: string) => `accountSelection-accountTile-${accountId}`,
  productModalButton: (accountId: string) =>
    `accountSelection-accountTile-productModalButton-${accountId}`,
};

const messages = defineMessages({
  selectAccount: {
    id: 'accountSelection.selectAccount',
    defaultMessage: 'Select An Account',
  },
  products: {
    id: 'accountSelection.products',
    defaultMessage: 'View {num} Products…',
  },
  logoAlt: {
    id: 'accountSelection.logoAlt',
    defaultMessage: '"{account}" logo',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
  },
  pill: {
    marginBottom: theme.spacing(0.75),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    color: theme.palette.common.white + '!important',
  },
  pillContainer: {
    flexWrap: 'wrap',
    width: '90%',
  },
  accountsContainer: {
    flexDirection: 'column',
    margin: 'auto',
    minWidth: theme.pxToRem(250),
    padding: theme.spacing(4),
    width: '80%',
  },
  accountsText: {
    fontSize: theme.pxToRem(24),
    marginBottom: theme.spacing(2),
  },
  accountsTextContainer: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  accountTilesContainer: {
    display: 'grid',
    transition: '.25s ease-in',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fit, 230px)',
    gridGap: 15,
    marginBottom: theme.spacing(2),
  },
  accountTile: {
    flex: 1,
    width: theme.pxToRem(230),
    minHeight: '12.5rem',
    transition: '.25s ease-in',
  },
  accountTileContentText: {
    margin: theme.spacing(2.5, 0, 1.25),
    fontSize: theme.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: theme.pxToRem(220),
    textOverflow: 'ellipsis',
  },
  tileHero: {
    background: theme.palette.graphite[300],
  },
  iconTileContent: {
    justifyContent: 'flex-start !important',
  },
  image: {
    height: '100%',
    padding: theme.spacing(2),
  },
  viewBtn: {
    marginTop: theme.spacing(-0.75),
  },
}));

const maybeRemoveArrayValue = (array: string[], value: string): string[] =>
  array.includes(value) ? array.splice(array.indexOf(value), 1) : array;

export interface AccountSelectionOwnProps {
  setSelectedAccountId: (id: string) => void;
  accounts: Account[];
}

export type AccountSelectionProps = AccountSelectionOwnProps;

const AccountSelection: React.FC<AccountSelectionProps> = (props: AccountSelectionProps) => {
  const [modalData, setModalData] = React.useState<string[] | undefined>(undefined);
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const { setSelectedAccountId, accounts } = props;
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles({});

  const handleClick = React.useCallback(
    (val: Account) => {
      setSelectedAccountId(val.id);
    },
    [setSelectedAccountId],
  );

  const handleProductsClick = React.useCallback(
    (products: string[]) => {
      setModalData(products);
      setModalIsOpen(true);
    },
    [setModalData, setModalIsOpen],
  );

  const products = (account: Account) =>
    account.products.length <= 3 ? (
      account.products.map((product: string) => (
        <Pill color="primary" className={classes.pill} key={product} label={product} />
      ))
    ) : (
      <Button
        className={classes.viewBtn}
        onClick={(e) => {
          e.stopPropagation();
          handleProductsClick(account.products);
        }}
        variant="text"
        {...getTestProps(testIds.productModalButton(account.id))}
      >
        {formatMessage(messages.products, { num: account.products.length })}
      </Button>
    );

  return (
    <Box className={classes.root} fullWidth fullHeight justify="center" align="center">
      <ProductsModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} products={modalData} />
      <div className={classes.accountsContainer}>
        <div className={classes.accountsTextContainer}>
          <Text weight="bold" className={classes.accountsText}>
            {formatMessage(messages.selectAccount)}
          </Text>
        </div>
        <div className={classes.accountTilesContainer}>
          {accounts.map((account: Account) => {
            if (account.products.includes('CorporateWellness'))
              maybeRemoveArrayValue(account.products, 'CorporateWellnessPaid');
            return (
              <IconTile
                className={classes.accountTile}
                onClick={() => handleClick(account)}
                key={account.id}
                {...getTestProps(testIds.iconTile(account.id))}
              >
                <IconTileHero className={classes.tileHero}>
                  <Box justify="center" align="center" fullHeight fullWidth>
                    <img
                      src={(account as any).logo ? (account as any).logo : phc}
                      className={classes.image}
                      alt={formatMessage(messages.logoAlt, {
                        account: account.name,
                      })}
                    />
                  </Box>
                </IconTileHero>
                <IconTileContent className={classes.iconTileContent}>
                  <Text className={classes.accountTileContentText}>{account.name}</Text>
                  <Box justify="center" align="center" className={classes.pillContainer}>
                    {products(account)}
                  </Box>
                </IconTileContent>
              </IconTile>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default React.memo(AccountSelection);
