import {
  createStore,
  applyMiddleware,
  ReducersMapObject,
  Middleware,
  AnyAction,
} from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createRootReducer from './rootReducer';
import { authActionTypes, authActions } from './modules/auth';
import { projectCohortPickerActionTypes } from './modules/projectCohortPicker';
import { projectPickerActionTypes } from './modules/projectPicker';
import { accountActionTypes } from './modules/account';
import { createPersistMiddleware } from './middleware/persistMiddleware';
import { createAsyncRequestMiddleware } from './middleware/asyncRequestMiddleware';
import { AsyncRequestMiddlewareOptions } from './middleware/asyncRequestMiddleware/asyncRequestMiddleware';
import persistedOCRPropsMiddleware from '../components/OCR/common/Viewer/redux/persistedOCRPropsMiddlewares';
import { window } from '../util/globals';

const buildManifest = process.env.BUILD_MANIFEST || {};

// @todo separate these

// Async middleware config
const asyncRequestMiddlewareOptions: AsyncRequestMiddlewareOptions = {
  onStatusCode: {
    401: store => store.dispatch(authActions.logout() as any),
  },
};

// persist middleware config
const persistActionTypes = [
  authActionTypes.AUTH_SUCCESS,
  authActionTypes.INITIATE_LOGOUT,
  authActionTypes.PROCESS_AUTH,
  authActionTypes.PROCESS_AUTH_END,
];

const filterRefreshToken = (store: any, action: AnyAction) =>
  persistActionTypes.indexOf(action.type) === -1
    ? store
    : {
        ...store,
        oauth: undefined,
      };

const onlyActiveAccountId = (store: any, action: AnyAction) => {
  if (action.type !== accountActionTypes.SET_ACTIVE_ACCOUNT) {
    return store;
  } else {
    return { activeAccount: action.account };
  }
};

const onlyActiveProjectId = (store: any, action: AnyAction) => {
  if (action.type !== projectPickerActionTypes.SET_ACTIVE_PROJECT) {
    return store;
  } else {
    return { activeProject: action.id };
  }
};

const activeProjectCohortIdAndLastUsedList = (
  store: any,
  action: AnyAction,
) => {
  if (
    action.type !== projectCohortPickerActionTypes.SET_ACTIVE_PROJECT_COHORT
  ) {
    return store;
  } else {
    const lastUsedCohortByProject = store.lastUsedCohortByProject;
    lastUsedCohortByProject[store.cohortListProject] = action.id;
    return {
      activeProjectCohort: action.id,
      lastUsedCohortByProject,
    };
  }
};

// log out performance metrics per action
const actionLoggerMiddleware = () => (next: any) => (action: any) => {
  if (typeof performance?.mark === 'function') {
    performance?.mark(`${action.type}_start`);
    const result = next(action);
    performance?.mark(`${action.type}_end`);
    performance?.measure(
      `${action.type}`,
      `${action.type}_start`,
      `${action.type}_end`,
    );

    return result;
  } else {
    return next(action);
  }
};

// middlewares
const baseMiddlewares = [
  thunkMiddleware,
  createAsyncRequestMiddleware(asyncRequestMiddlewareOptions),
  createPersistMiddleware(
    [
      {
        actionTypes: persistActionTypes,
        reducer: 'auth',
        storage: 'localStorage',
      },
    ],
    filterRefreshToken,
  ),
  createPersistMiddleware(
    [
      {
        actionTypes: [projectCohortPickerActionTypes.SET_ACTIVE_PROJECT_COHORT],
        reducer: 'projectCohortPicker',
        storage: 'localStorage',
      },
    ],
    activeProjectCohortIdAndLastUsedList,
  ),
  createPersistMiddleware(
    [
      {
        actionTypes: [projectPickerActionTypes.SET_ACTIVE_PROJECT],
        reducer: 'projectPicker',
        storage: 'localStorage',
      },
    ],
    onlyActiveProjectId,
  ),
  createPersistMiddleware(
    [
      {
        actionTypes: [accountActionTypes.SET_ACTIVE_ACCOUNT],
        reducer: 'account',
        storage: 'localStorage',
      },
    ],
    onlyActiveAccountId,
  ),
  persistedOCRPropsMiddleware,
  process.env.NODE_ENV !== 'production' && actionLoggerMiddleware,
  process.env.NODE_ENV !== 'production' &&
    process.env.REDUX_LOGGER &&
    createLogger(),
].filter(Boolean);

export default function configureStore(
  state: any,
  additionalReducers?: ReducersMapObject<any>,
  additionalMiddleware: Middleware[] = [],
) {
  // eslint-disable-next-line no-console
  if (
    window &&
    (window as any).console &&
    typeof (window as any).console.log === 'function'
  ) {
    // eslint-disable-next-line no-console
    (window as any).console.log('Build Manifest:', buildManifest);
  }

  const store = createStore(
    createRootReducer(additionalReducers) as any,
    state,
    composeWithDevTools(
      applyMiddleware(
        ...(baseMiddlewares as any),
        ...(additionalMiddleware as any),
      ) as any,
    ) as any,
  );

  if ((module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => {
      const nextCreateRootReducer = require('./rootReducer');
      store.replaceReducer(nextCreateRootReducer(additionalReducers));
    });
  }

  return store;
}
