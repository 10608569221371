import deepFreeze from 'deep-freeze-strict';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { window } from '@lifeomic/phc-web-toolkit/dist/util/globals';
import { basename } from './appConfig';

const urls = {
  externalApps: {
    login: (originalUrl: string) =>
      `/login${stringifyParams({
        originalUrl,
      })}`,
    logout: (originalUrl: string, idp?: 'facebook' | 'google') =>
      `/login${stringifyParams({
        originalUrl,
        logout: 'pending',
        idp,
      })}`,
    me: (params: Record<string, any>) => `/me${stringifyParams(params)}`,
    PHC: (params: Record<string, any>) => `/phc${stringifyParams(params)}`,
    precisionOCR: (params: Record<string, any>) => `/precision-ocr${stringifyParams(params)}`,
  },
  app: {
    logoutRedirect: () => `/${basename}/logout`,
  },
};

/**
 * Helpers
 */
function stringifyParams(params: Record<string, any>) {
  return isEmpty(params) ? '' : `?${queryString.stringify(params)}`;
}

export function baseEnvUrl(uri: string, baseSubdomain = 'apps'): string {
  const hostParts = window.location.host.split('.');
  const host =
    hostParts.length === 1
      ? hostParts.join('.')
      : hostParts[0] === baseSubdomain && hostParts[1] !== baseSubdomain
      ? hostParts.join('.')
      : hostParts.slice(1).join('.');
  return `${window.location.protocol}//${host}${uri.startsWith('/') ? uri : '/' + uri}`;
}

export default {
  ...deepFreeze(urls),
};
