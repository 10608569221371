import {
  createAsyncActionTypes,
  createLifeomicActionTypes,
} from '../../util/createActionTypes';

const actions = createLifeomicActionTypes([
  'SET_ACTIVE_PROJECT',
  'UNSELECT_PROJECT',
  'HYDRATE_ACTIVE_PROJECT',
]);

const asyncActions = createAsyncActionTypes([
  'FETCH_PROJECTS',
  'FETCH_PROJECTS_SUCCESS',
  'FETCH_PROJECTS_ERROR',
]);

export default {
  ...actions,
  ...asyncActions,
};
