import asyncRequestContextsActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { FetchState } from '../../util/fetchReducers';
import actions from './actions';

type ResetContextAction = ReturnType<typeof actions.resetContext>;
type RequestStartAction = ReturnType<typeof actions.requestStart>;
type RequestSuccessAction = ReturnType<typeof actions.requestSuccess>;
type RequestErrorAction = ReturnType<typeof actions.requestError>;
type DeleteContextAction = ReturnType<typeof actions.deleteContext>;

export interface AsyncRequestContext {
  responses?: any[];
  nextToken?: string;
  request?: Partial<FetchState>;
  count?: number;
}

export interface AsyncRequestContextState {
  activeRequests: { [key: string]: string };
  contexts: {
    [key: string]: AsyncRequestContext;
  };
}

const INITIAL_STATE: AsyncRequestContextState = {
  activeRequests: {},
  contexts: {},
};

const reducer = createReducer(INITIAL_STATE, {
  [asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_RESET]: (
    state,
    action: ResetContextAction,
  ) => {
    return {
      ...state,
      activeRequests: {
        ...state.activeRequests,
        [action.requestContextKey]: null, // "cancel" any active requests if they exist
      },
      contexts: {
        ...state.contexts,
        [action.requestContextKey]: {
          responses: [],
          nextToken: null,
          request: {},
        },
      },
    };
  },
  [asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_START]: (
    state,
    action: RequestStartAction,
  ) => {
    return {
      ...state,
      contexts: {
        ...state.contexts,
        [action.requestContextKey]: {
          ...state.contexts[action.requestContextKey],
          request: {
            ..._get(state.contexts[action.requestContextKey], 'request', {}),
            response: null,
            isFetching: true,
            error: null,
          },
        },
      },
      activeRequests: {
        ...state.activeRequests,
        [action.requestContextKey]: action.requestKey,
      },
    } as any;
  },
  [asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_SUCCESS]: (
    state,
    action: RequestSuccessAction,
  ) => {
    const disableResponseConcatenation = _get(
      action,
      'meta.disableResponseConcatenation',
      false,
    );

    const currentResponses = disableResponseConcatenation
      ? []
      : _get(state, `contexts.${action.requestContextKey}.responses`, []);

    const normalizedResponse = Array.isArray(action.parsedResponses)
      ? action.parsedResponses
      : [action.parsedResponses];

    return {
      ...state,
      contexts: {
        ...state.contexts,
        [action.requestContextKey]: {
          ...state.contexts[action.requestContextKey],
          count: action.count,
          nextToken: action.nextToken,
          responses: [...currentResponses, ...normalizedResponse],
          request: {
            ..._get(state.contexts[action.requestContextKey], 'request', {}),
            isFetching: false,
            error: null,
            response: action.response,
            lastFetchAttempt: new Date(),
          },
        },
      },
    } as any;
  },
  [asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_ERROR]: (
    state,
    action: RequestErrorAction,
  ) => {
    return {
      ...state,
      contexts: {
        ...state.contexts,
        [action.requestContextKey]: {
          ...state.contexts[action.requestContextKey],
          request: {
            ..._get(state.contexts[action.requestContextKey], 'request', {}),
            isFetching: false,
            error: action.error,
            errorResponse: action.errorResponse,
            lastFetchAttempt: new Date(),
            status: action.status,
          },
        },
      },
    };
  },
  [asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_DELETE]: (
    state,
    action: DeleteContextAction,
  ) => {
    const contextsToKeep = _omit(state.contexts, action.requestContextKeys);
    return {
      ...state,
      contexts: {
        ...contextsToKeep,
      },
    };
  },
});

export default reducer;
