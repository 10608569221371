import {
  createAsyncActionTypes,
  createLifeomicActionTypes,
} from '../../util/createActionTypes';

const actions = createLifeomicActionTypes([
  'SET_ACTIVE_PROJECT_COHORT',
  'HYDRATE_ACTIVE_PROJECT_COHORT',
  'SET_COHORT_LIST_PROJECT',
]);

const asyncActions = createAsyncActionTypes([
  'FETCH_PROJECT_COHORTS',
  'FETCH_PROJECT_COHORTS_SUCCESS',
  'FETCH_PROJECT_COHORTS_ERROR',
]);

export default {
  ...actions,
  ...asyncActions,
};
