import * as React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import urls from '../../util/urls';

export const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '10rem',
    opacity: 0.4,
  },
}));

const messages = {
  notFound: {
    id: 'notFound.404',
    defaultMessage: "The page you're looking for could not be found.",
  },
};

const NotFound = () => {
  const classes = useStyles({});
  const { formatMessage } = useIntl();

  return (
    <Box fullWidth direction="column" align="center" justify="center">
      <Box align="center" padding={7} className={classes.text}>
        <h2>404</h2>
      </Box>

      <Box padding={4}>
        <Box direction="column" align="center">
          <p>{formatMessage(messages.notFound)}</p>
          <p>
            Click <Link to={urls.app.welcome}>here</Link> to return home
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(NotFound);
