import * as React from 'react';
import { makeStyles } from '@lifeomic/chroma-react/styles';
import { Box } from '@lifeomic/chroma-react/components/Box';
import Bkgd from '@lifeomic/phc-web-toolkit/dist/static/images/TessellatedMountains.svg';

export const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    background: `url(${Bkgd}) no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    backfaceVisibility: 'hidden',
  },
}));

const BrandedBackground: React.FC = ({ children }) => {
  const classes = useStyles({});

  return (
    <Box fullWidth fullHeight align="center" justify="center" className={classes.root}>
      {children}
    </Box>
  );
};

export default BrandedBackground;
