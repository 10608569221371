import {
  createLifeomicActionTypes,
  createAsyncActionTypes,
} from '../../util/createActionTypes';

const actions = createLifeomicActionTypes([
  'SET_ACTIVE_ACCOUNT',
  'HYDRATE_ACTIVE_ACCOUNT',
]);

const asyncRequests = createAsyncActionTypes([
  'FETCH_ACCOUNTS',
  'FETCH_ACCOUNTS_START',
  'FETCH_ACCOUNTS_SUCCESS',
  'FETCH_ACCOUNTS_ERROR',
  'DELETE_ACCOUNT',
  'DELETE_ACCOUNT_START',
  'DELETE_ACCOUNT_SUCCESS',
  'DELETE_ACCOUNT_ERROR',
  'UPDATE_ACCOUNT',
  'UPDATE_ACCOUNT_START',
  'UPDATE_ACCOUNT_SUCCESS',
  'UPDATE_ACCOUNT_ERROR',
]);

export default {
  ...asyncRequests,
  ...actions,
};
