import { createAsyncActionTypes } from '../../util/createActionTypes';

export default createAsyncActionTypes([
  'FETCH_USER_GROUPS',
  'FETCH_USER_GROUPS_START',
  'FETCH_USER_GROUPS_SUCCESS',
  'FETCH_USER_GROUPS_ERROR',
  'FETCH_ACCOUNT_GROUPS',
  'FETCH_ACCOUNT_GROUPS_START',
  'FETCH_ACCOUNT_GROUPS_SUCCESS',
  'FETCH_ACCOUNT_GROUPS_ERROR',
  'CREATE_GROUP',
  'CREATE_GROUP_START',
  'CREATE_GROUP_SUCCESS',
  'CREATE_GROUP_ERROR',
  'UPDATE_GROUP',
  'UPDATE_GROUP_START',
  'UPDATE_GROUP_SUCCESS',
  'UPDATE_GROUP_ERROR',
  'DELETE_GROUP',
  'DELETE_GROUP_START',
  'DELETE_GROUP_SUCCESS',
  'DELETE_GROUP_ERROR',
]);
