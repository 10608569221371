const actionPrefixes = {
  LIFEOMIC: '@@lifeomic',
  ASYNC: 'async',
};

const lifeAccount = 'lifeomiclife';

const asyncActionSuffixes = {
  start: '_START',
  failure: '_ERROR',
  success: '_SUCCESS',
  reset: '_$RESET',
};

const conditions = [
  { code: 254837009, label: 'Malignant neoplasm of breast (disorder)' },
  { code: 10509002, label: 'Acute bronchitis (disorder)' },
  { code: 185086009, label: 'Chronic obstructive bronchitis (disorder)' },
  { code: 68496003, label: 'Polyp of colon' },
  { code: 38341003, label: 'Hypertension' },
  { code: 15777000, label: 'Prediabetes' },
  { code: 44054006, label: 'Diabetes' },
  { code: 162573006, label: 'Suspected lung cancer (situation)' },
  { code: 87433001, label: 'Pulmonary emphysema (disorder)' },
].map(val => ({ ...val, id: `${val.code}|${val.label}` }));

const extensions = {
  patient: {
    hl7: {
      fhir: {
        usCore: {
          usCoreRace:
            'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
          usCoreEthnicity:
            'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
          usCoreBirthsex:
            'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
        },
        codeSystem: {
          race: 'http://hl7.org/fhir/v3/Race',
          genderIdentity: 'http://hl7.org/fhir/gender-identity',
        },
        usCoreRace: 'http://hl7.org/fhir/StructureDefinition/us-core-race',
        usCoreEthnicity:
          'http://hl7.org/fhir/StructureDefinition/us-core-ethnicity',
        usCoreBirthsex:
          'http://hl7.org/fhir/StructureDefinition/us-core-birthsex',
        birthPlace: 'http://hl7.org/fhir/StructureDefinition/birthPlace',
        mr: 'http://hl7.org/fhir/v2/0203',
        genderIdentity:
          'http://hl7.org/fhir/StructureDefinition/patient-genderIdentity',
      },
    },
  },
};

const loinc = {
  bmi: '39156-5',
  cholesterol: '2093-3',
  geneticVariant: '47997-2',
  glucose: '2345-7',
  race: '2106-3',
  ethnicity: '2186-5',
  Bilirubin: '1974-5',
  creatinine: '38483-4',
  platelet: '26515-7',
  // RI specific loinc codes, delete this once configurability is available
  RI_bilirubin: '1975-2',
  RI_creatinine: '2160-0',
};

export {
  actionPrefixes,
  asyncActionSuffixes,
  loinc,
  conditions,
  extensions,
  lifeAccount,
};
