import * as React from 'react';
import { Box } from '@lifeomic/chroma-react/components/Box';
import BrandedBackground from '../../common/BrandedBackground';
import { DotLoader } from '@lifeomic/chroma-react/components/DotLoader';

const Loading: React.FC = () => (
  <BrandedBackground>
    <Box fullHeight fullWidth direction="column" justify="center" align="center">
      <Box borderRadius direction="column" justify="center" align="center" padding={2}>
        <DotLoader />
      </Box>
    </Box>
  </BrandedBackground>
);

export default Loading;
