import { ViewerState } from './types';
import defaultState, { buildDefaultState } from './defaultState';
import { createReducer } from '../../../../../redux/util/createReducer';
import actionTypes from './actionTypes';

const initialState: ViewerState = defaultState;

const reducer = createReducer<ViewerState>(initialState, {
  [actionTypes.OCR_INITIALIZE_STATE_VALUE]: (_state, action) => {
    return buildDefaultState(
      action.docRefId,
      action.pendingViewerFocus,
      action.persistedState,
    );
  },
  [actionTypes.OCR_SET_IS_TEXTRACT_DATA_PROCESSING]: (state, action) => {
    return {
      ...state,
      textractDataProcessing: {
        ...state.textractDataProcessing,
        isProcessing: action.isProcessing,
      },
    };
  },
  [actionTypes.OCR_SET_PROCESSED_TEXTRACT_DATA]: (state, action) => {
    return {
      ...state,
      textractDataProcessing: {
        ...state.textractDataProcessing,
        processedData: action.processedData,
      },
    };
  },
  [actionTypes.OCR_SET_PROJECT_CLASSIFICATIONS]: (state, action) => {
    return {
      ...state,
      projectClassifications: action.projectClassifications,
    };
  },
  [actionTypes.OCR_SET_VIEWER_DOCS]: (state, action) => {
    return {
      ...state,
      viewerDocs: action.viewerDocs,
    };
  },
  [actionTypes.OCR_SET_COMPOSITION_CACHE]: (state, action) => {
    return {
      ...state,
      compositionCache: action.compositions,
    };
  },
  [actionTypes.OCR_SET_IS_SPELL_CHECK_ACTIVE]: (state, action) => {
    return {
      ...state,
      isSpellCheckActive: action.isSpellCheckActive,
    };
  },
  [actionTypes.OCR_SET_RAW_TEXTRACT_DATA]: (state, action) => {
    return {
      ...state,
      rawTextractData: action.rawTextractData,
    };
  },
  [actionTypes.OCR_SET_IS_MASK_ACTIVE]: (state, action) => {
    return {
      ...state,
      isMaskActive: action.isMaskActive,
    };
  },
  [actionTypes.OCR_SET_IS_DOCUMENT_DATES_ACTIVE]: (state, action) => {
    return {
      ...state,
      isDocumentDatesActive: action.isDocumentDatesActive,
    };
  },
  [actionTypes.OCR_SET_IS_DOCUMENT_DETAILS_MODE_ACTIVE]: (state, action) => {
    return {
      ...state,
      isDocumentDetailsModeActive: action.isDocumentDetailsModeActive,
    };
  },
  [actionTypes.OCR_SET_IS_EXTRACTED_SUMMARY_ACTIVE]: (state, action) => {
    return {
      ...state,
      isExtractedSummaryModeActive: action.isExtractedSummaryModeActive,
    };
  },
  [actionTypes.OCR_SET_IS_BOX_SELECTING]: (state, action) => {
    return {
      ...state,
      boxSelection: {
        ...state.boxSelection,
        isSelecting: action.isSelecting,
      },
    };
  },
  [actionTypes.OCR_SET_BOX_SELECTION_ELEMENT_TYPE]: (state, action) => {
    return {
      ...state,
      boxSelection: {
        ...state.boxSelection,
        selectionElementType: action.selectionElementType,
      },
    };
  },
  [actionTypes.OCR_SET_BOX_SELECTED_LINES]: (state, action) => {
    return {
      ...state,
      boxSelection: {
        ...state.boxSelection,
        selectedLines: action.selectedLines,
      },
    };
  },
  [actionTypes.OCR_SET_BOX_SELECTED_WORDS]: (state, action) => {
    return {
      ...state,
      boxSelection: {
        ...state.boxSelection,
        selectedWords: action.selectedWords,
      },
    };
  },
  [actionTypes.OCR_RESET_BOX_SELECTION_STATE]: (state, _action) => {
    return {
      ...state,
      boxSelection: defaultState.boxSelection,
    };
  },
  [actionTypes.OCR_SET_ZOOM]: (state, action) => {
    return {
      ...state,
      zoomMultiplyer: action.zoomMultiplyer,
    };
  },
  [actionTypes.OCR_SET_IS_ANALYZE_MODE_ACTIVE]: (state, action) => {
    return {
      ...state,
      analyze: {
        ...state.analyze,
        isAnalyzeModeActive: action.isAnalyzeModeActive,
      },
    };
  },
  [actionTypes.OCR_SET_FOCUSED_RESOURCE]: (state, action) => {
    return {
      ...state,
      analyze: {
        ...state.analyze,
        focusedAnalyzeResource: action.focusedAnalyzeResource,
      },
    };
  },
  [actionTypes.OCR_SET_ANALYZED_RESOURCES_SEARCH_TEXT]: (state, action) => {
    return {
      ...state,
      analyze: {
        ...state.analyze,
        analyzedResourcesSearchText: action.analyzedResourcesSearchText,
      },
    };
  },
  [actionTypes.OCR_SET_ANALYZE_INPUT]: (state, action) => {
    return {
      ...state,
      analyze: {
        ...state.analyze,
        analyzeInput: action.analyzeInput,
      },
    };
  },
  [actionTypes.OCR_RESET_ANALYZE_STATE]: state => {
    return {
      ...state,
      analyze: defaultState.analyze,
    };
  },
  [actionTypes.OCR_SET_ANALYZE_STATE]: (state, action) => {
    return {
      ...state,
      analyze: action.analyzeState,
    };
  },
  [actionTypes.OCR_SET_SEARCH_FOUND_PHRASES]: (state, action) => {
    return {
      ...state,
      search: {
        ...state.search,
        foundPhrases: action.foundPhrases,
      },
    };
  },
  [actionTypes.OCR_SET_SEARCH_FOCUSED_PHRASE]: (state, action) => {
    return {
      ...state,
      search: {
        ...state.search,
        focusedPhrase: action.focusedPhrase,
      },
    };
  },
  [actionTypes.OCR_SET_IS_SHOWING_SEARCH_RESULT_LIST]: (state, action) => {
    return {
      ...state,
      search: {
        ...state.search,
        showResultList: action.showResultList,
      },
    };
  },
  [actionTypes.OCR_RESET_SEARCH_STATE]: state => {
    return {
      ...state,
      search: defaultState.search,
    };
  },
  [actionTypes.OCR_SET_PICK_LIST]: (state, action) => {
    return {
      ...state,
      pickList: {
        ...state.pickList,
        pickListItems: action.pickListItems,
      },
    };
  },
  [actionTypes.OCR_SET_PICK_LIST_FOCUSED]: (state, action) => {
    return {
      ...state,
      pickList: {
        ...state.pickList,
        isFocused: action.isFocused,
      },
    };
  },
  [actionTypes.OCR_SET_SOURCE_DOC]: (state, action) => {
    return {
      ...state,
      sourceDoc: action.sourceDoc,
    };
  },
  [actionTypes.OCR_SET_RENDERED_PAGE_COUNT]: (state, action) => {
    return {
      ...state,
      renderedPageCount: action.renderedPageCount,
    };
  },
  [actionTypes.OCR_SET_INGESTION_SUGGESTIONS]: (state, action) => {
    return {
      ...state,
      ingestedSuggestions: action.ingestionSuggestions,
    };
  },
  [actionTypes.OCR_SET_PENDING_VIEWER_FOCUS]: (state, action) => {
    return {
      ...state,
      pendingViewerFocus: action.pendingViewerFocus,
    };
  },
  [actionTypes.OCR_SET_INGESTION_SUGGESTIONS_ACTIVE]: (state, action) => {
    return {
      ...state,
      isIngestionSuggestionsActive: action.isIngestionSuggestionsActive,
    };
  },
  [actionTypes.OCR_SET_DISPLAYED_INGESTION_SUGGESTION_TYPES]: (
    state,
    action,
  ) => {
    return {
      ...state,
      displayedIngestionSuggetionTypes: action.displayedIngestionSuggetionTypes,
    };
  },
  [actionTypes.OCR_SET_DISPLAY_COMPREHEND_SUGGESTIONS]: (state, action) => {
    return {
      ...state,
      displayComprehendSuggestions: action.displayComprehendSuggestions,
    };
  },
  [actionTypes.OCR_SET_DISABLED_SEARCH_LISTS]: (state, action) => {
    return {
      ...state,
      disabledSearchLists: action.disabledSearchLists,
    };
  },
  [actionTypes.OCR_SET_REPORT_EXTRACTORS]: (state, action) => {
    return {
      ...state,
      reportExtractors: action.reportExtractors,
    };
  },
  [actionTypes.OCR_SET_DATA_MODE]: (state, action) => {
    return {
      ...state,
      dataMode: action.dataMode,
    };
  },
  [actionTypes.OCR_SET_IS_QUICK_NAV_OPEN]: (state, action) => {
    return {
      ...state,
      isQuickNavOpen: action.isQuickNavOpen,
    };
  },
  [actionTypes.OCR_SET_HIGHLIGHT_NEGATED_SUGGESTIONS]: (state, action) => {
    return {
      ...state,
      highlightNegatedSuggestions: action.highlightNegatedSuggestions,
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_SELECTED_RANGE]: (state, action) => {
    return {
      ...state,
      organize: {
        ...state.organize,
        selectionRange: action.selectionRange || null,
      },
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_GROUPING_MODE]: (state, action) => {
    return {
      ...state,
      organize: {
        ...state.organize,
        groupingMode: action.groupingMode || 'stack',
      },
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_POST_UPDATE_PAGE_SCROLL_INDEX]: (
    state,
    action,
  ) => {
    return {
      ...state,
      organize: {
        ...state.organize,
        postUpdatePageScrollIndex: action.postUpdatePageScrollIndex || null,
      },
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_ZOOM]: (state, action) => {
    return {
      ...state,
      organizeZoom: action.organizeZoom,
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_DATE_HIGHLIGHTS]: (state, action) => {
    return {
      ...state,
      organizeDateHighlight: action.organizeDateHighlight,
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_DATE_SUGGEST]: (state, action) => {
    return {
      ...state,
      organizeDateSuggest: action.organizeDateSuggest,
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_COLLAPSED_SECTIONS]: (state, action) => {
    return {
      ...state,
      organize: {
        ...state.organize,
        collapsedSectionKeys: action.collapsedSectionKeys,
      },
    };
  },
  [actionTypes.OCR_INCREMENT_COMPOSITION_CACHE_UPDATE_COUNT]: state => {
    return {
      ...state,
      compositionCacheUpdateCount: state.compositionCacheUpdateCount + 1,
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_HIDE_CONFIRMED_PAGES]: (state, action) => {
    return {
      ...state,
      organizeHideConfirmedPages: action.organizeHideConfirmedPages,
    };
  },
  [actionTypes.OCR_SET_OCR_SETTINGS]: (state, { ocrSettings }) => {
    return {
      ...state,
      ocrSettings,
    };
  },
  [actionTypes.OCR_SET_OCR_COMPOSITION_DUPLICATES]: (
    state,
    { compositionDuplicates },
  ) => {
    return {
      ...state,
      compositionDuplicates: {
        ...state.compositionDuplicates,
        compositionDuplicates,
      },
    };
  },
  [actionTypes.OCR_SET_OCR_SET_FOCUSED_COMPOSITION_DUPLICATES]: (
    state,
    { focusedCompositionDuplicates },
  ) => {
    return {
      ...state,
      compositionDuplicates: {
        ...state.compositionDuplicates,
        focusedCompositionDuplicates,
      },
    };
  },
  [actionTypes.OCR_SET_OCR_HOVER_ZOOM_ACTIVE]: (
    state,
    { isHoverZoomActive },
  ) => {
    return {
      ...state,
      hoverZoom: {
        ...state.hoverZoom,
        isHoverZoomActive,
      },
    };
  },
  [actionTypes.OCR_SET_PAGE_IN_VIEW]: (state, { pageIndex, isInView }) => {
    const newPages = [...(state.pagesInView || new Set<number>())].filter(
      _ => _ !== pageIndex,
    );
    if (isInView) newPages.push(pageIndex);

    return {
      ...state,
      pagesInView: new Set(newPages),
    };
  },
  [actionTypes.OCR_SET_ORGANIZE_PAGE_IN_VIEW]: (
    state,
    { pageIndex, isInView },
  ) => {
    const newPages = [
      ...(state.organizePagesInView || new Set<number>()),
    ].filter(_ => _ !== pageIndex);
    if (isInView) newPages.push(pageIndex);

    return {
      ...state,
      organizePagesInView: new Set(newPages),
    };
  },
  [actionTypes.OCR_SET_QUERY_STATE_ACTIVE]: (state, { isQueryStateActive }) => {
    return {
      ...state,
      query: {
        isQueryStateActive: isQueryStateActive,
        focusedQueryId: null,
      },
    };
  },
  [actionTypes.OCR_SET_FOCUSED_QUERY]: (state, { focusedQueryId }) => {
    return {
      ...state,
      query: {
        isQueryStateActive: true,
        focusedQueryId,
      },
    };
  },
});

export default reducer;
