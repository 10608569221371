import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@lifeomic/chroma-react/styles';
import { theme } from '@lifeomic/phc-web-toolkit/dist/theme/phc';
import RootErrorBoundary from './common/RootErrorBoundary';
import { basename } from '../util/appConfig';
import App from './App';

const Root: React.FC = ({ store }: any) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" textComponent={React.Fragment}>
        <ReduxProvider store={store}>
          <RootErrorBoundary>
            <Router basename={`/${basename}`}>
              <App />
            </Router>
          </RootErrorBoundary>
        </ReduxProvider>
      </IntlProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Root;
