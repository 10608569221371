import {
  createLifeomicActionTypes,
  createAsyncActionTypes,
} from '../../util/createActionTypes';

const asyncActionTypes = createAsyncActionTypes([
  'EXCHANGE_ACCESS_TOKEN',
  'EXCHANGE_ACCESS_TOKEN_START',
  'EXCHANGE_ACCESS_TOKEN_SUCCESS',
  'EXCHANGE_ACCESS_TOKEN_ERROR',
  'REFRESH_ACCESS_TOKEN',
  'REFRESH_ACCESS_TOKEN_START',
  'REFRESH_ACCESS_TOKEN_SUCCESS',
  'REFRESH_ACCESS_TOKEN_ERROR',
  'GLOBAL_LOGOUT',
  'GLOBAL_LOGOUT_START',
  'GLOBAL_LOGOUT_SUCCESS',
  'GLOBAL_LOGOUT_ERROR',
  'FETCH_COOKIE_FEATURE_TOGGLE',
  'EXCHANGE_COOKIE',
  'EXCHANGE_COOKIE_SUCCESS',
  'EXCHANGE_COOKIE_ERROR',
  'REFRESH_COOKIE',
  'REFRESH_COOKIE_SUCCESS',
  'REFRESH_COOKIE_ERROR',
  'FETCH_CLIENT_ID',
]);

const actionTypes = createLifeomicActionTypes([
  'HYDRATE_AUTH',
  'HYDRATE_AUTH_FAIL',
  'AUTH_SUCCESS',
  'PROCESS_AUTH',
  'PROCESS_AUTH_START',
  'PROCESS_AUTH_END',
  'PROCESS_AUTH_ERROR',
  'SET_OAUTH_CLIENT_ID',
  'AUTH_CLEAR_ERROR',
  'INITIATE_LOGOUT',
  'LOGOUT_FAIL',
  'CHECK_AUTH_TOKEN_EXPIRATION',
  'CHECK_COOKIE_EXPIRATION',
  'RESET_COOKIE',
]);

export default {
  ...asyncActionTypes,
  ...actionTypes,
};
