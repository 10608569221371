import urls, { EvaluatedPolicyUrlOptions } from '../../../util/urls';
import actionTypes from './actionTypes';
import { Dispatch } from 'redux';

function getResponseContextProps(contextKey: string) {
  if (!contextKey) return null;

  return {
    contextKey,
    parseResponseItems: (data: any) => data,
  };
}

function fetchEvaluatedPolicy(params: EvaluatedPolicyUrlOptions = {}) {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: actionTypes.FETCH_EVALUATED_POLICY,
      url: urls.api.evaluatedPolicy(params),
      responseContext: getResponseContextProps(params.user || 'current'),
    });
  };
}

export default {
  fetchEvaluatedPolicy,
};
