import { createActionTypes } from '../../util/createActionTypes';

export default createActionTypes([
  'ASYNC_REQUEST_CONTEXT_SET_ACTIVE_REQUEST',
  'ASYNC_REQUEST_CONTEXT_PUSH_RESPONSE',
  'ASYNC_REQUEST_CONTEXT_RESET',
  'ASYNC_REQUEST_CONTEXT_DELETE',
  'ASYNC_REQUEST_CONTEXT_SET_NEXT_TOKEN',
  'ASYNC_REQUEST_CONTEXT_FETCH_START',
  'ASYNC_REQUEST_CONTEXT_FETCH_SUCCESS',
  'ASYNC_REQUEST_CONTEXT_FETCH_ERROR',
]);
