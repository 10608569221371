import { window } from '../globals';

/**
 * Attempts to determine if local storage is enabled in the browser
 *
 * @returns {boolean} - "true" if local storage is enabled
 */
function isLocalStorageEnabled(): boolean {
  try {
    // some browsers will completely remove the localStorage object in private mode
    if (
      !window.localStorage ||
      !window.localStorage.setItem ||
      !window.localStorage.getItem ||
      !window.localStorage.removeItem
    ) {
      return false;
    }

    const key = 'tempKey';
    const value = 'tempValue';

    window.localStorage.removeItem(key); // just in case somehow hanging around from previous check
    if (window.localStorage.getItem(key)) return false;

    // test item can be set and retrieved
    window.localStorage.setItem(key, value);
    const returnedItem = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);

    return returnedItem === value;
  } catch (e) {
    console.error('Local storage check failed with error:', e);
    return false;
  }
}

export default isLocalStorageEnabled;
