import {
  createActionTypes,
  createAsyncActionTypes,
} from '../../util/createActionTypes';

const asyncActions = createAsyncActionTypes([
  'PROJECT_CONSENT_FORM',
  'FETCH_PROJECT_CONSENTS',
  'FETCH_CONSENT_FORMS',
  'FETCH_USER_CONSENTS',
  'CREATE_USER_CONSENT',
  'UPDATE_USER_CONSENT',
  'DELETE_PROJECT_PATIENT',
  'FETCH_CONSENT_QUESTIONNAIRE',
  'FETCH_CONSENT_QUESTIONNAIRE_RESPONSE',
  'FETCH_CONSENT_DEFAULT_FORM',
  'FETCH_PATIENT_DIRECTIVES',
]);

const actions = createActionTypes([
  'SET_PROJECT_FORM',
  'RESET_PROJECT_FORM',
  'RESET_CONSENT_DETAIL_STATE',
  'SET_CONSENT_DETAIL_CONSENT',
  'SET_CONSENT_DETAIL_QUESTIONNAIRE',
  'SET_CONSENT_DETAIL_QUESTIONNAIRE_RESPONSE',
  'SET_CONSENT_DETAIL_INITIALIZED_STATE',
]);

export default {
  ...asyncActions,
  ...actions,
};
