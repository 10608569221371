import urls from '../../../util/urls';
import accountActionTypes from './actionTypes';
import { Dispatch } from 'redux';
import { getPersistedStore } from '../../middleware/persistMiddleware';
import NotificationHelper from '../../util/NotificationHelper';

function fetchAccounts() {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: accountActionTypes.FETCH_ACCOUNTS,
      url: urls.api.accounts.accounts(),
    });
  };
}

function deleteAccount(id: string) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: accountActionTypes.DELETE_ACCOUNT,
      url: urls.api.accounts.account(id),
      fetchOptions: {
        method: 'DELETE',
      },
    });
  };
}

function updateAccount(
  id: string,
  account: any,
  notification = new NotificationHelper(),
) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: accountActionTypes.UPDATE_ACCOUNT,
      url: urls.api.accounts.account(id),
      fetchOptions: {
        method: 'PATCH',
        body: JSON.stringify(account),
      },
      ...notification.getAsyncRequestHandlers(),
    });
  };
}

function setActiveAccount(accountId: string) {
  return {
    type: accountActionTypes.SET_ACTIVE_ACCOUNT,
    account: accountId,
  };
}

function hydrateActiveAccount() {
  return {
    type: accountActionTypes.HYDRATE_ACTIVE_ACCOUNT,
    activeAccount: (getPersistedStore('account') || {}).activeAccount,
  };
}

export default {
  deleteAccount,
  updateAccount,
  fetchAccounts,
  setActiveAccount,
  hydrateActiveAccount,
};
