import _last from 'lodash/last';
import {
  selectContexts,
  selectKeyedContext,
  selectContextResponses,
  selectIsFetching,
  selectContextRequest,
  selectRequestError,
} from '../asyncRequestContexts/selectors';
import * as abac from '@lifeomic/abac';

export const selectEvaluatedPolicyState = (
  state: any,
): abac.AbacReducedPolicy => state.evaluatedPolicy;

const emptyRules = { rules: {} };

const selectRequestContext = (state: any, user = 'current'): any => {
  const contexts = selectContexts(state);
  return selectKeyedContext(contexts, user);
};

export const selectPolicyStateForUser = (
  state: any,
  user = 'current',
): abac.AbacReducedPolicy => {
  const targetContext = selectRequestContext(state, user);

  const responses = selectContextResponses(targetContext);

  return _last(responses) || emptyRules;
};

export const selectPolicyForUser = (state: any, user = 'current') =>
  selectPolicyStateForUser(state, user);

export const selectPolicyForCurrentUser = (state: any) =>
  selectPolicyStateForUser(state, 'current');

export const selectPolicyIsFetchingForUser = (
  state: any,
  user = 'current',
): boolean => {
  const targetContext = selectRequestContext(state, user);
  const request = selectContextRequest(targetContext);
  return selectIsFetching(request);
};

export const selectPolicyErrorForUser = (state: any, user = 'current'): any => {
  const targetContext = selectRequestContext(state, user);
  const request = selectContextRequest(targetContext);
  return selectRequestError(request);
};

/** @deprecated use selectPolicyForUser */
export const selectPolicy = (state: any) =>
  selectPolicyForUser(state, 'current');

/** @deprecated use selectPolicyIsFetchingForUser */
export const selectPolicyIsFetching = selectPolicyIsFetchingForUser;

/** @deprecated use selectPolicyErrorForUser */
export const selectPolicyError = selectPolicyErrorForUser;
