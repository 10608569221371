import { createLifeomicActionTypes } from '../../../../../redux/util/createActionTypes';

export default createLifeomicActionTypes([
  'OCR_INITIALIZE_STATE_VALUE',
  'OCR_SET_VIEWER_DOCS',
  'OCR_SET_SOURCE_DOC',
  'OCR_SET_RAW_TEXTRACT_DATA',
  'OCR_SET_IS_TEXTRACT_DATA_PROCESSING',
  'OCR_SET_PROCESSED_TEXTRACT_DATA',
  'OCR_SET_COMPOSITION_CACHE',
  'OCR_SET_PROJECT_CLASSIFICATIONS',
  'OCR_SET_IS_SPELL_CHECK_ACTIVE',
  'OCR_SET_IS_MASK_ACTIVE',
  'OCR_SET_IS_DOCUMENT_DATES_ACTIVE',
  'OCR_SET_IS_DOCUMENT_DETAILS_MODE_ACTIVE',
  'OCR_SET_IS_EXTRACTED_SUMMARY_ACTIVE',
  'OCR_SET_IS_BOX_SELECTING',
  'OCR_SET_BOX_SELECTION_ELEMENT_TYPE',
  'OCR_SET_BOX_SELECTED_LINES',
  'OCR_SET_BOX_SELECTED_WORDS',
  'OCR_RESET_BOX_SELECTION_STATE',
  'OCR_SET_ZOOM',
  'OCR_SET_ASPECT_RATIO',
  'OCR_SET_IS_ANALYZE_MODE_ACTIVE',
  'OCR_SET_FOCUSED_RESOURCE',
  'OCR_SET_ANALYZE_INPUT',
  'OCR_RESET_ANALYZE_STATE',
  'OCR_SET_ANALYZE_STATE',
  'OCR_SET_SEARCH_FOUND_PHRASES',
  'OCR_SET_SEARCH_FOCUSED_PHRASE',
  'OCR_SET_IS_SHOWING_SEARCH_RESULT_LIST',
  'OCR_RESET_SEARCH_STATE',
  'OCR_SET_PICK_LIST',
  'OCR_SET_PICK_LIST_FOCUSED',
  'OCR_SET_RENDERED_PAGE_COUNT',
  'OCR_SET_INGESTION_SUGGESTIONS',
  'OCR_SET_PENDING_VIEWER_FOCUS',
  'OCR_SET_INGESTION_SUGGESTIONS_ACTIVE',
  'OCR_SET_DISPLAYED_INGESTION_SUGGESTION_TYPES',
  'OCR_SET_DISPLAY_COMPREHEND_SUGGESTIONS',
  'OCR_SET_DISABLED_SEARCH_LISTS',
  'OCR_SET_REPORT_EXTRACTORS',
  'OCR_SET_DATA_MODE',
  'OCR_SET_ANALYZED_RESOURCES_SEARCH_TEXT',
  'OCR_SET_IS_QUICK_NAV_OPEN',
  'OCR_SET_HIGHLIGHT_NEGATED_SUGGESTIONS',
  'OCR_SET_ORGANIZE_SELECTED_RANGE',
  'OCR_SET_ORGANIZE_GROUPING_MODE',
  'OCR_SET_ORGANIZE_POST_UPDATE_PAGE_SCROLL_INDEX',
  'OCR_SET_ORGANIZE_ZOOM',
  'OCR_SET_ORGANIZE_DATE_HIGHLIGHTS',
  'OCR_SET_ORGANIZE_DATE_SUGGEST',
  'OCR_SET_ORGANIZE_COLLAPSED_SECTIONS',
  'OCR_INCREMENT_COMPOSITION_CACHE_UPDATE_COUNT',
  'OCR_SET_ORGANIZE_HIDE_CONFIRMED_PAGES',
  'OCR_SET_OCR_SETTINGS',
  'OCR_SET_OCR_COMPOSITION_DUPLICATES',
  'OCR_SET_OCR_SET_FOCUSED_COMPOSITION_DUPLICATES',
  'OCR_SET_OCR_HOVER_ZOOM_ACTIVE',
  'OCR_SET_PAGE_IN_VIEW',
  'OCR_SET_ORGANIZE_PAGE_IN_VIEW',
  'OCR_SET_QUERY_STATE_ACTIVE',
  'OCR_SET_FOCUSED_QUERY',
]);
