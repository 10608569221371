import {
  createLifeomicActionTypes,
  createAsyncActionTypes,
} from '../../util/createActionTypes';

const asyncActionTypes = createAsyncActionTypes([
  'FETCH_FEATURES',
  'FETCH_UNAUTH_FEATURES',
]);

const actionTypes = createLifeomicActionTypes([
  'TOGGLE_FEATURE',
  'TOGGLE_FEATURES',
]);

export default {
  ...asyncActionTypes,
  ...actionTypes,
};
