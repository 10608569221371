import { window } from '../../../util/globals';
import { Middleware, Action } from 'redux';

const storageStrategyTypes = new Map();
const storagePrefix = '@@lifeomic/store/';

const storageStrategies = {
  cookie: {
    set: (k: string, v: any) => {
      window.document.cookie = `${k}=${JSON.stringify(v)}`;
      storageStrategyTypes.set(k, 'cookie');
    },
    get: (k: string) => {
      const match = window.document.cookie.match(new RegExp(k + '=([^;]+)'));
      return match ? match[1] : null;
    },
  },
  localStorage: {
    set: (k: string, v: any) => {
      window.localStorage.setItem(k, JSON.stringify(v));
      storageStrategyTypes.set(k, 'localStorage');
    },
    get: (k: string) => window.localStorage.getItem(k),
  },
  sessionStorage: {
    set: (k: string, v: any) => {
      window.sessionStorage.setItem(k, JSON.stringify(v));
      storageStrategyTypes.set(k, 'sessionStorage');
    },
    get: (k: string) => window.sessionStorage.getItem(k),
  },
};

type StorageStrategyKey = keyof typeof storageStrategies;

function createPersistMiddleware(
  toPersist: Array<any>,
  fn?: (reducer: any, action: Action) => any,
): Middleware {
  return store => next => action => {
    const result = next(action);
    toPersist
      .filter(
        item =>
          item.reducer &&
          (!item.actionTypes || item.actionTypes.indexOf(action.type) > -1),
      )
      .map(item => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.info(
            `Persisting store - Action: ${action.type}, Reducer: ${item.reducer}`,
          );
        }
        const toStore =
          typeof fn === 'function'
            ? fn(store.getState()[item.reducer], action)
            : store.getState()[item.reducer];
        // Default to sessionStorage
        let strategy: StorageStrategyKey;
        if (item.storage) {
          if (!(item.storage in storageStrategies)) {
            throw new Error(
              'Invalid storage strategy provided.  Valid strategies are `cookie`, `localStorage`, or `sessionStorage`',
            );
          }
          strategy = item.storage;
        } else {
          strategy = 'localStorage';
        }
        storageStrategies[strategy].set(storagePrefix + item.reducer, toStore);
      });
    return result;
  };
}

const sortedStorageStrategies: StorageStrategyKey[] = [
  'localStorage',
  'sessionStorage',
  'cookie',
];

function getPersistedStore(store: string, fn?: (data: any) => any) {
  const strategy: StorageStrategyKey = storageStrategyTypes.get(
    storagePrefix + store,
  );
  let retrieved;
  if (!strategy) {
    // Attempt retrieval from localStorage -> sessionStorage -> cookie
    // @todo make strategies a map to preserve order and do away with this
    sortedStorageStrategies.some(s => {
      retrieved = storageStrategies[s].get(storagePrefix + store) || null;
      return !!retrieved;
    });
  } else {
    retrieved = storageStrategies[strategy].get(storagePrefix + store) || null;
  }
  return typeof fn === 'function'
    ? fn(JSON.parse(retrieved))
    : JSON.parse(retrieved);
}

export { createPersistMiddleware, getPersistedStore };
