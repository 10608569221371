/**
 * Util functions to interact with cookies.  Pulled into a separate file to make
 * mocking in tests easier 🙃
 */

export function getCookies() {
  return typeof window !== 'undefined' && window.document
    ? window.document.cookie
    : '';
}

export function getCookieByName(name: string) {
  return getCookies()
    ?.split('; ')
    ?.find(cookie => cookie.startsWith(`${name}=`))
    ?.split('=')?.[1];
}

export function deleteCookieByName(name: string) {
  const cookie = getCookieByName(name);

  if (!cookie) {
    return;
  }

  window.document.cookie = `${name}=;path=/;domain=${window.location.host.substring(
    window.location.host.indexOf('apps.') + 4,
    window.location.host.length,
  )};expires=${new Date(0).toUTCString()};secure=true`;
}
