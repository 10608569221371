import * as React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = {
  error: {
    id: 'errorBoundary.error',
    defaultMessage: 'An error has occurred...  sorry.',
  },
};

const ErrorMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <Box justify="center" align="center" padding={20}>
      <Text size="headline">{formatMessage(messages.error)}</Text>
    </Box>
  );
};
class ErrorBoundary extends React.Component<unknown, { hasError: boolean }> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorMessage /> : children;
  }
}

export default ErrorBoundary;
