import accountActionTypes from './actionTypes';
import {
  createAsyncRequestReducer,
  AsyncRequestReducerOptions,
} from '../../util/fetchReducers';
import { Account } from '../../../common/types/Accounts';

export interface AccountState {
  activeAccount: string | null;
  isFetching: boolean;
  error: any;
  response: {
    accounts: Account[];
  };
  lastFetchAttempt: Date | null;
}

const options: AsyncRequestReducerOptions<AccountState> = {
  initialStateProps: {
    activeAccount: null,
    isFetching: false,
    error: null,
    response: {
      accounts: [],
    },
    lastFetchAttempt: null,
  },
  reducers: {
    [accountActionTypes.SET_ACTIVE_ACCOUNT]: (state, action) => {
      return {
        ...state,
        activeAccount: action.account,
      };
    },
    [accountActionTypes.HYDRATE_ACTIVE_ACCOUNT]: (state, action) => {
      return {
        ...state,
        activeAccount: action.activeAccount,
      };
    },
    [accountActionTypes.UPDATE_ACCOUNT_START]: state => {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [accountActionTypes.UPDATE_ACCOUNT_SUCCESS]: (state, action) => {
      const index = state.response.accounts.findIndex(
        account => account.id === action.response.id,
      );

      return {
        ...state,
        isFetching: false,
        error: null,
        lastFetchAttempt: new Date(),
        response: {
          accounts: [
            ...state.response.accounts.slice(0, index),
            action.response,
            ...state.response.accounts.slice(index + 1),
          ],
        },
      };
    },
    [accountActionTypes.UPDATE_ACCOUNT_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastFetchAttempt: new Date(),
      };
    },
    [accountActionTypes.DELETE_ACCOUNT_START]: state => {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [accountActionTypes.DELETE_ACCOUNT_SUCCESS]: (state, action) => {
      const index = state.response.accounts.findIndex(
        account => account.id === action.response.id,
      );

      return {
        ...state,
        isFetching: false,
        error: null,
        lastFetchAttempt: new Date(),
        response: {
          accounts: [
            ...state.response.accounts.slice(0, index),
            action.response,
            ...state.response.accounts.slice(index + 1),
          ],
        },
      };
    },
    [accountActionTypes.DELETE_ACCOUNT_ERROR]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastFetchAttempt: new Date(),
      };
    },
  },
};

export default createAsyncRequestReducer(
  accountActionTypes.FETCH_ACCOUNTS,
  options,
);
