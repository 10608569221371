import { Dispatch } from 'redux';
import urls from '../../../util/urls';
import userPatientsActionTypes from './actionTypes';

function fetchUserPatients(accountId: string) {
  return (dispatch: Dispatch) => {
    if (!accountId) {
      return dispatch(
        fetchUserPatientsError(
          new Error("Account ID required to fetch user's associated patients"),
        ),
      );
    } else {
      return dispatch({
        type: userPatientsActionTypes.FETCH_USER_PATIENTS,
        url: urls.api.fhir.me(),
      });
    }
  };
}

function fetchUserPatientsError(error: any) {
  return {
    type: userPatientsActionTypes.FETCH_USER_PATIENTS_ERROR,
    error,
  };
}

/**
 * Instead of loading the user's patients with the account ID from state, this
 * will explicetely set the user patient.
 *
 * This is useful if the user's patient is already loaded and injected from a parent application
 */
function explicitelyAssignUserPatient(patient: fhir.Patient) {
  return {
    type: userPatientsActionTypes.EXPLICITELY_ASSIGN_USER_PATIENT,
    patient,
  };
}

export default {
  fetchUserPatients,
  explicitelyAssignUserPatient,
};
