import _reduce from 'lodash/reduce';
import { Dispatch } from 'redux';
import { parseAuthResponse, getJwtPayload } from '../../util/parseAuthResponse';
import { getPersistedStore } from '../../middleware/persistMiddleware';
import authActionTypes from './actionTypes';
import urls, { getHashParams, stringifyParams } from '../../../util/urls';
import { window } from '../../../util/globals';
import queryString from 'query-string';
import {
  selectOauth,
  selectOauthClientId,
  selectRefreshToken,
  selectIdToken,
  selectAuthState,
  selectCookieState,
} from './selectors';
import { getCookieByName, deleteCookieByName } from '../../util/cookieUtils';
import { AuthState } from './reducer';
import { getAccountFromVanityUrl } from '../../util/windowUtils';
// Kind of a no-no to cross-polinate redux actions, but for this particular
// case, we have to.
import { selectOauthClientId as configSelectOAuthClientId } from '../config/selectors';
import { clearAuthStorage } from '../../../util/authStorage';

export const EXPIRES_COOKIE_NAME = 'lifeomic-auth-expires';
const FOUR_MINUTES_IN_MS = 4 * 60000;
let initialCookieRefreshIntervalId: number = null;
let cookieRefreshIntervalId: number = null;

export interface ProcessAuthArgs {
  /**
   * @description Exposes a way for clients to completely bypass
   * cookie auth processing if they want to, even if the feature toggle
   * is enabled.
   */
  skipCookieProcessing?: boolean;
}

interface StateQueryParams {
  account?: string;
  projectId?: string;
}

const getOriginUri = (params: StateQueryParams = {}) => {
  const qps = params.account || params.projectId ? stringifyParams(params) : '';

  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    qps
  );
};

function hydrateAuth() {
  return function (dispatch: Dispatch) {
    const filterExpired = (retrieved: any) => {
      if (retrieved === null) return null;
      if (parseInt(retrieved.expiresAt, 10) > Date.now()) return retrieved;
      else return null;
    };
    const validRetrievedStore = getPersistedStore('auth', filterExpired);
    const action: {
      type: string;
      auth?: {
        isAuthenticated: boolean;
        isProcessing: boolean;
      };
    } = { type: authActionTypes.HYDRATE_AUTH };
    if (validRetrievedStore) {
      action.auth = validRetrievedStore;
      action.auth.isAuthenticated = true;
      action.auth.isProcessing = false;
      dispatch(action);
    } else {
      dispatch(action);
    }
  };
}

/**
 * Explicitely set the auth state.  This is useful when container application already has auth token.
 *
 * This will set auth in a way that this app will never refresh the token.  Because of this, it's
 * assumed that the parent application manages token refreshes
 */
function setAuthFromParentApplication(accessToken: string) {
  return {
    type: authActionTypes.HYDRATE_AUTH,
    auth: {
      isAuthenticated: true,
      isProcessing: false,
      accessToken: accessToken,
      expiresAt: Number.MAX_SAFE_INTEGER,
    },
  };
}

interface CookiesFeatureToggle {
  issueCookies?: boolean;
}

export interface CookieExchangeResponse {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  clientId: string;
}

export interface CookieRefreshResponse {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
  tokenType?: any;
  clientId: string;
}

interface DispatchRequest<T> {
  status?: number;
  error?: Error;
  response?: T;
}

function exchangeCookie() {
  return async function (dispatch: Dispatch, getState: () => any) {
    const expiresCookie = getCookieByName(EXPIRES_COOKIE_NAME);

    if (!expiresCookie) {
      return;
    }

    const featureToggleResponse = (await dispatch({
      type: authActionTypes.FETCH_COOKIE_FEATURE_TOGGLE,
      url: urls.api.publicFeatures(null, 'issueCookies'),
    })) as DispatchRequest<CookiesFeatureToggle>;

    if (!featureToggleResponse?.response?.issueCookies) {
      return;
    }

    const persistedStore: AuthState = getPersistedStore('auth');

    if (expiresCookie && persistedStore?.cookieState?.isLoggedInWithCookie) {
      dispatch({
        type: authActionTypes.EXCHANGE_COOKIE_SUCCESS,
        cookieState: {
          ...persistedStore?.cookieState,
        },
      });
      return;
    }

    const cookieExchangeResponse = (await dispatch({
      type: authActionTypes.EXCHANGE_COOKIE,
      url: urls.api.auth.cookie(),
    })) as DispatchRequest<CookieExchangeResponse>;

    if (!cookieExchangeResponse?.response?.accessToken) {
      dispatch({
        type: authActionTypes.EXCHANGE_COOKIE_ERROR,
        error: cookieExchangeResponse.error,
      });
      return;
    }

    dispatch({
      type: authActionTypes.EXCHANGE_COOKIE_SUCCESS,
      cookieState: {
        ...cookieExchangeResponse?.response,
      },
    });

    const cookieData = cookieExchangeResponse?.response;
    const jwtPayload = getJwtPayload(cookieData.idToken);

    dispatch({
      type: authActionTypes.PROCESS_AUTH,
      auth: {
        ...{
          accessToken: cookieData?.accessToken,
          idToken: cookieData?.idToken,
          refreshToken: cookieData?.refreshToken,
          error: null,
          errorDescription: null,
          expiresAt: parseInt(expiresCookie),
        },
        isAuthenticated: Boolean(cookieData?.accessToken),
        jwtPayload,
        oauthClientId:
          cookieData?.clientId || configSelectOAuthClientId(getState()),
      },
    });
  };
}

interface ClientIdResponse {
  clientId: string;
}

export const NON_VANITY_URLS = ['app', 'apps', 'wellness'];

function validateSSO() {
  return async function (dispatch: Dispatch, getState: () => any) {
    const clientIdFromEnvVar = configSelectOAuthClientId(getState());
    const maybeAccountFromVanityUrl = getAccountFromVanityUrl();
    const persistedStore: AuthState = getPersistedStore('auth');
    const cookieState = persistedStore?.cookieState;
    const authState = persistedStore;

    if (!authState?.isAuthenticated) {
      return;
    }

    if (!cookieState?.isLoggedInWithCookie) {
      return;
    }

    if (
      NON_VANITY_URLS.includes(maybeAccountFromVanityUrl) &&
      cookieState?.clientId !== clientIdFromEnvVar
    ) {
      if (!clientIdFromEnvVar) {
        console.warn(
          'No oauth client id was found in the environment variables. Check to see if your app needs to have this environment variable.',
        );
      }

      await dispatch(logout());
      return;
    }

    if (NON_VANITY_URLS.includes(maybeAccountFromVanityUrl)) {
      return;
    }

    if (maybeAccountFromVanityUrl?.startsWith('localhost')) {
      return;
    }

    if (!cookieState?.clientId) {
      return;
    }

    const clientIdResponse = (await dispatch({
      type: authActionTypes.FETCH_CLIENT_ID,
      url: urls.api.auth.clientIdWithSubdomain(maybeAccountFromVanityUrl),
      fetchOptions: {
        method: 'GET',
        headers: {
          Authorization: authState?.accessToken,
        },
      },
    })) as DispatchRequest<ClientIdResponse>;

    if (!clientIdResponse?.response?.clientId) {
      await dispatch(logout());
      return;
    }

    if (
      clientIdResponse?.response?.clientId?.toLowerCase() !==
      cookieState?.clientId?.toLowerCase()
    ) {
      await dispatch(logout());
      return;
    }
  };
}

const AUTH_TOKEN_INTERVAL = 30 * 1000; // every 30 seconds
const AUTH_REFRESH_WINDOW = 5; // minutes before token expiration that we renew
let authTokenRefresh: number;

function processAuth({ skipCookieProcessing = false }: ProcessAuthArgs = {}) {
  return async function (dispatch: Dispatch, getState: () => any) {
    dispatch(processAuthStart());

    const expiresCookie = getCookieByName(EXPIRES_COOKIE_NAME);

    if (!skipCookieProcessing) {
      await dispatch<any>(exchangeCookie());
    }

    dispatch<any>(hydrateAuth());

    const { isLoggedInWithCookie } = selectCookieState(getState());

    const hashParams = getHashParams(window.location.href);
    if (hashParams?.error || hashParams?.errorDescription) {
      dispatch(
        processAuthError({
          error: hashParams.error,
          description: hashParams.errorDescription,
        }),
      );
    }
    const code = (
      (queryString.parse(queryString.extract(window.location.href))
        .code as string) || ''
    ).replace(/#$/, ''); // @todo google login seems to append this
    const clientId =
      (queryString.parse(queryString.extract(window.location.href))
        .clientId as string) || '';
    if (code) {
      try {
        await dispatch(setOauthClientId(clientId));
        await dispatch<any>(fetchAccessToken(code));
        const state = getState();
        if (
          selectOauth(state).operation ===
            authActionTypes.EXCHANGE_ACCESS_TOKEN &&
          !selectOauth(state).error
        ) {
          const response = selectOauth(state).response;
          const jwtPayload = getJwtPayload(response.id_token);
          const parsedAuthResponse = parseAuthResponse(response);
          dispatch({
            type: authActionTypes.PROCESS_AUTH,
            auth: {
              ...parsedAuthResponse,
              isAuthenticated: !!parsedAuthResponse.accessToken,
              jwtPayload,
            },
          });

          const stateQps: StateQueryParams = {
            account: queryString.parse(window.location.search)
              .account as string,
            projectId: queryString.parse(window.location.search)
              .projectId as string,
          };

          window.history.pushState({}, '', getOriginUri(stateQps));
        }
      } catch (error) {
        dispatch(
          processAuthError({
            error: error,
            description: error.toString(),
          }),
        );
      }
    }

    await dispatch(validateSSO());

    // initiate refresh
    if (!authTokenRefresh && !isLoggedInWithCookie) {
      authTokenRefresh = window.setInterval(() => {
        dispatch<any>(checkAccessTokenExpiry(AUTH_REFRESH_WINDOW));
      }, AUTH_TOKEN_INTERVAL);
    }

    if (isLoggedInWithCookie && !initialCookieRefreshIntervalId) {
      initialCookieRefreshIntervalId = window.setTimeout(() => {
        dispatch(checkCookieExpiry(AUTH_REFRESH_WINDOW));
        clearInterval(initialCookieRefreshIntervalId);
      }, parseInt(expiresCookie) - Date.now() - FOUR_MINUTES_IN_MS);
    }

    dispatch(processAuthEnd());
  };
}

function processAuthStart() {
  return {
    type: authActionTypes.PROCESS_AUTH_START,
    auth: { isProcessing: true },
  };
}

function processAuthEnd() {
  return {
    type: authActionTypes.PROCESS_AUTH_END,
    auth: { isProcessing: false },
  };
}

function processAuthError(error: any) {
  return { type: authActionTypes.PROCESS_AUTH_ERROR, error };
}

function clearError() {
  return { type: authActionTypes.AUTH_CLEAR_ERROR };
}

function setOauthClientId(clientId: string) {
  return {
    type: authActionTypes.SET_OAUTH_CLIENT_ID,
    clientId,
  };
}

async function dispatchLogout(
  dispatch: Dispatch,
  getState: () => any,
  redirectPath: string,
  global = false,
) {
  const state = getState();
  const idToken = selectIdToken(state);
  const persistedStore: AuthState = getPersistedStore('auth');
  const isLoggedInWithCookie =
    persistedStore?.cookieState?.isLoggedInWithCookie;

  if (global || isLoggedInWithCookie) {
    const response = (await dispatch({
      type: authActionTypes.GLOBAL_LOGOUT,
      url: urls.api.auth.logout(),
      fetchOptions: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          removeCookies: isLoggedInWithCookie,
          global,
        }),
      },
    })) as any;

    if (response.status !== 200 && response.error) {
      processAuthError({
        error: response.error,
        description: response.error?.toString(),
      });
    }

    if (isLoggedInWithCookie) {
      dispatch({ type: authActionTypes.RESET_COOKIE, cookieState: {} });

      if (cookieRefreshIntervalId) {
        clearInterval(cookieRefreshIntervalId);
      }

      if (initialCookieRefreshIntervalId) {
        clearInterval(initialCookieRefreshIntervalId);
      }

      // If our logout request fails for some reason, manually delete
      // the cookie so it gets removed from the browser
      if (response.status !== 200) {
        deleteCookieByName(EXPIRES_COOKIE_NAME);
      }
    }
  }

  dispatch({ type: authActionTypes.INITIATE_LOGOUT });
  clearAuthStorage();
  if (authTokenRefresh) {
    clearInterval(authTokenRefresh);
  }
  if (idToken) {
    (window.location as any) = redirectPath;
  } else {
    (window.location as any) = '/';
  }
}

function logout(redirectPath = '/phc/logout') {
  return async function (dispatch: Dispatch, getState: () => any) {
    return dispatchLogout(dispatch, getState, redirectPath);
  };
}

function globalLogout(redirectPath = '/phc/logout') {
  return async function (dispatch: Dispatch, getState: () => any) {
    return dispatchLogout(dispatch, getState, redirectPath, true);
  };
}

function fetchAccessToken(code: string) {
  return async (dispatch: Dispatch, getState: () => any) => {
    if (!code) {
      return dispatch({
        type: authActionTypes.EXCHANGE_ACCESS_TOKEN_ERROR,
        error: new Error('Invalid code supplied for accessToken fetch'),
      });
    }

    const body = _reduce(
      {
        grant_type: 'authorization_code',
        client_id: selectOauthClientId(getState()),
        redirect_uri: urls.api.auth.redirectUri(),
        code,
      },
      (str, v, k) =>
        `${str ? `${str}&` : str}${encodeURIComponent(k)}=${encodeURIComponent(
          v,
        )}`,
      '',
    );
    await dispatch({
      type: authActionTypes.EXCHANGE_ACCESS_TOKEN,
      url: urls.oauth.token(),
      fetchOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      },
    });
  };
}

function checkAccessTokenExpiry(minutes = 5) {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: authActionTypes.CHECK_AUTH_TOKEN_EXPIRATION });
    const authStore = selectAuthState(getState());
    const { expiresAt } = authStore;
    if (
      expiresAt &&
      Math.floor((expiresAt - Date.now()) / 1000 / 60) <= minutes
    ) {
      await dispatch<any>(refreshAccessToken());
    }
  };
}

function checkCookieExpiry(minutes = 5) {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: authActionTypes.CHECK_COOKIE_EXPIRATION });
    const authStore = selectAuthState(getState());
    const { expiresAt } = authStore;
    if (
      expiresAt &&
      Math.floor((expiresAt - Date.now()) / 1000 / 60) <= minutes
    ) {
      await dispatch<any>(refreshCookie());
    }
  };
}

function refreshCookie() {
  return async (dispatch: Dispatch, getState: () => any) => {
    const cookieRefreshResponse = (await dispatch({
      type: authActionTypes.REFRESH_COOKIE,
      url: urls.api.auth.cookie(),
      excludeAuth: true,
      fetchOptions: {
        method: 'POST',
      },
    })) as DispatchRequest<CookieRefreshResponse>;

    if (!cookieRefreshResponse?.response?.accessToken) {
      dispatch({
        type: authActionTypes.REFRESH_COOKIE_ERROR,
        error: cookieRefreshResponse.error,
      });
      return;
    }

    dispatch({
      type: authActionTypes.REFRESH_COOKIE_SUCCESS,
    });

    const cookieData = cookieRefreshResponse?.response;
    const jwtPayload = getJwtPayload(cookieData.idToken);

    dispatch({
      type: authActionTypes.PROCESS_AUTH,
      auth: {
        ...{
          accessToken: cookieData?.accessToken,
          idToken: cookieData?.idToken,
          refreshToken: cookieData?.refreshToken,
          error: null,
          errorDescription: null,
          expiresAt: new Date().getTime() + cookieData.expiresIn * 1000,
        },
        isAuthenticated: Boolean(cookieData?.accessToken),
        jwtPayload,
        oauthClientId:
          cookieData?.clientId || configSelectOAuthClientId(getState()),
      },
    });

    if (!cookieRefreshIntervalId) {
      cookieRefreshIntervalId = window.setInterval(() => {
        dispatch(checkCookieExpiry(AUTH_REFRESH_WINDOW));
      }, cookieData.expiresIn * 1000 - FOUR_MINUTES_IN_MS);
    }
  };
}

function refreshAccessToken() {
  return async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    const body = _reduce(
      {
        grant_type: 'refresh_token',
        client_id: selectOauthClientId(state),
        redirect_uri: urls.api.auth.redirectUri(),
        refresh_token: selectRefreshToken(state),
      },
      (str, v, k) =>
        `${str ? `${str}&` : str}${encodeURIComponent(k)}=${encodeURIComponent(
          v,
        )}`,
      '',
    );
    await dispatch({
      type: authActionTypes.REFRESH_ACCESS_TOKEN,
      url: urls.oauth.token(),
      excludeAuth: true,
      fetchOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
      },
    });
    const newState = getState();
    if (
      selectOauth(newState).operation ===
        authActionTypes.REFRESH_ACCESS_TOKEN &&
      !selectOauth(newState).error
    ) {
      const response = selectOauth(newState).response;
      const jwtPayload = getJwtPayload(response.id_token);
      const parsedAuthResponse = parseAuthResponse(response);
      delete parsedAuthResponse.refreshToken;
      dispatch({
        type: authActionTypes.PROCESS_AUTH,
        auth: {
          ...parsedAuthResponse,
          isAuthenticated: !!parsedAuthResponse.accessToken,
          jwtPayload,
        },
      });
    }
  };
}

export default {
  getOriginUri,
  hydrateAuth,
  processAuth,
  fetchAccessToken,
  refreshAccessToken,
  processAuthError,
  clearError,
  logout,
  globalLogout,
  exchangeCookie,
  refreshCookie,
  validateSSO,
  setAuthFromParentApplication,
};
