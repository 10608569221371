import { CohortPickerState } from './reducer';
import { createSelector } from 'reselect';
import * as abac from '@lifeomic/abac';
import { projectPickerSelectors } from '../projectPicker';
import { selectPolicyForUser } from '../evaluatedPolicy/selectors';
import { Cohort } from '../../../services/CohortService';

const emptyState: CohortPickerState = {} as CohortPickerState;

export const selectProjectCohortPickerState = (state: any): CohortPickerState =>
  state.projectCohortPicker || emptyState;

export const selectActiveProjectCohortId = (state: any): string | null =>
  selectProjectCohortPickerState(state).activeProjectCohort;

export const selectProjectCohortList = (state: any) =>
  selectProjectCohortPickerState(state).projectCohortList;

export const selectActiveProjectCohort = createSelector(
  selectActiveProjectCohortId,
  selectProjectCohortList,
  (activeProjectCohortId, projectCohortList) => {
    return (projectCohortList || []).find(p => p.id === activeProjectCohortId);
  },
);

export const selectIsFetching = (state: any) =>
  selectProjectCohortPickerState(state).isFetching;

export const selectError = (state: any) =>
  selectProjectCohortPickerState(state).error;

export const selectLastFetchAttempt = (state: any) =>
  selectProjectCohortPickerState(state).lastFetchAttempt;

export const selectCohortListProject = (state: any) =>
  selectProjectCohortPickerState(state).cohortListProject;

/**
 * Each project change requires refetching that project's cohorts.  UI rendering
 * and project-change-context-switching should wait on cohort initialization.
 *
 * If cohort initialization is not awaited on, downstream data fetches won't have
 * the appropriate cohort parameters and api requests would resolve with ABAC errors.
 *
 * Ensure project changes resolve a new cohort list THEN continue with initializing data
 */
export const areCohortsInitializing = createSelector(
  projectPickerSelectors.selectActiveProjectId,
  selectIsFetching,
  selectCohortListProject,
  (activeProjectId, isFetchingCohorts, cohortListProject) => {
    if (isFetchingCohorts) return true;

    /*
      it is possible that the active project ID has changed but the
      cohort list is still for the previous project ID.  The app would likely
      only be in this state for a very brief period of time, but still long enough
      for invalid API requests to initiate
    */
    if (cohortListProject !== activeProjectId) return true;

    return false;
  },
);

const hasAccessToProject = (
  policy: abac.AbacReducedPolicy,
  projectId: string,
) => {
  const attributes = {
    resource: {
      dataset: projectId,
      cohorts: ['unspecified'],
      cohortAffinity: true,
    },
  };
  return (
    abac.enforceLenient('readData', policy, attributes) ||
    abac.enforceLenient('readMaskedData', policy, attributes)
  );
};

/**
 * Determines if the current user is restricted to only viewing specific cohorts
 * within the active project.  Note that this will restrict the user to specific cohorts
 * while cohorts are still initializing
 */
export const isUserAccessRestrictedToCohorts = createSelector(
  selectPolicyForUser,
  projectPickerSelectors.selectActiveProjectId,
  (userPolicy, projectId) => {
    return !hasAccessToProject(userPolicy, projectId);
  },
);

/**
 * Of the raw list of filters from the API, determines what cohorts are selectable based on
 *  - the initialization state
 *  - cohort deletion status
 */
export const selectableCohorts = createSelector(
  areCohortsInitializing,
  selectProjectCohortList,
  projectPickerSelectors.selectActiveProjectId,
  (areCohortsInitializing, projectCohortList): Cohort[] => {
    if (areCohortsInitializing) return [];

    return (projectCohortList || []).filter(
      _ => !_.deletionDate || new Date(_.deletionDate).getTime() > Date.now(),
    );
  },
);

/**
 * Will default to the widest available access.  If the user has general project access, will return null (specifying no cohort restriction).
 * If the user only has access to cohorts, returns the first available cohort
 */
export const defaultCohortSelection = createSelector(
  isUserAccessRestrictedToCohorts,
  selectableCohorts,
  (isUserRestrictedToCohorts, selectableCohorts): Cohort | null => {
    return isUserRestrictedToCohorts ? selectableCohorts[0] || null : null;
  },
);
