import asyncRequestContextsActionTypes from './actionTypes';

function resetContext(requestContextKey: string) {
  return {
    type: asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_RESET,
    requestContextKey,
  };
}

function deleteContext(...requestContextKeys: string[]) {
  return {
    type: asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_DELETE,
    requestContextKeys: requestContextKeys,
  };
}

function requestStart(
  requestContextKey: string,
  requestKey: string,
  meta?: any,
) {
  return {
    meta,
    type: asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_START,
    requestContextKey,
    requestKey,
  };
}

function requestSuccess(
  requestContextKey: string,
  response: any,
  parsedResponses: any | any[],
  nextToken: string,
  meta?: any,
  count?: number,
) {
  return {
    meta,
    type: asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_SUCCESS,
    requestContextKey,
    response,
    parsedResponses,
    nextToken,
    count,
  };
}

function requestError(
  requestContextKey: string,
  error: any,
  meta?: any,
  status?: number,
  data?: any,
) {
  return {
    meta,
    type: asyncRequestContextsActionTypes.ASYNC_REQUEST_CONTEXT_FETCH_ERROR,
    requestContextKey,
    error,
    status,
    errorResponse: data,
  };
}

export default {
  resetContext,
  requestStart,
  requestSuccess,
  requestError,
  deleteContext,
};
