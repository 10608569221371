export type Action<A = { [key: string]: any }> = A & {
  type: string;
  meta?: any;
};

export type Reducer<State> = (state: State, action: Action<any>) => State;

export interface ReducerMap<State> {
  [actionType: string]: Reducer<State>;
}

function createReducer<State>(
  initialState: State,
  handlers: ReducerMap<State>,
): Reducer<State> {
  return (state = initialState, action) => {
    return Object.prototype.hasOwnProperty.call(handlers, action.type)
      ? handlers[action.type](state, action)
      : state;
  };
}

export { createReducer };
