import consentActionTypes from './actionTypes';
import { createAsyncRequestReducer } from '../../util/fetchReducers';
import { combineReducers } from 'redux';
import { createReducer } from '../../util/createReducer';

const ACTIVE_PROJECT_FORM_INITIAL_STATE = {
  form: null as any,
};

const CONSENT_DETAIL_INITIAL_STATE = {
  initialized: false,
  consent: null as any,
  questionnaire: null as any,
  questionnaireResponse: null as any,
};

export const reducers = {
  projectConsentForm: createAsyncRequestReducer(
    consentActionTypes.PROJECT_CONSENT_FORM,
  ),
  fetchProjectConsents: createAsyncRequestReducer(
    consentActionTypes.FETCH_PROJECT_CONSENTS,
  ),
  fetchConsentForms: createAsyncRequestReducer(
    consentActionTypes.FETCH_CONSENT_FORMS,
  ),
  fetchUserConsents: createAsyncRequestReducer(
    consentActionTypes.FETCH_USER_CONSENTS,
  ),
  updateUserConsent: createAsyncRequestReducer(
    consentActionTypes.UPDATE_USER_CONSENT,
  ),
  fetchConsentQuestionnaire: createAsyncRequestReducer(
    consentActionTypes.FETCH_CONSENT_QUESTIONNAIRE,
  ),
  fetchConsentQuestionnaireResponse: createAsyncRequestReducer(
    consentActionTypes.FETCH_CONSENT_QUESTIONNAIRE_RESPONSE,
  ),

  activeProjectForm: createReducer(ACTIVE_PROJECT_FORM_INITIAL_STATE, {
    [consentActionTypes.SET_PROJECT_FORM]: (state, action) => {
      return {
        ...state,
        form: action.form,
      };
    },
    [consentActionTypes.RESET_PROJECT_FORM]: () => {
      return ACTIVE_PROJECT_FORM_INITIAL_STATE;
    },
  }),
  consentDetail: createReducer(CONSENT_DETAIL_INITIAL_STATE, {
    [consentActionTypes.RESET_CONSENT_DETAIL_STATE]: () => {
      return CONSENT_DETAIL_INITIAL_STATE;
    },
    [consentActionTypes.SET_CONSENT_DETAIL_INITIALIZED_STATE]: (
      state,
      action,
    ) => {
      return {
        ...state,
        initialized: action.initialized,
      };
    },
    [consentActionTypes.SET_CONSENT_DETAIL_CONSENT]: (state, action) => {
      return {
        ...state,
        consent: action.consent,
      };
    },
    [consentActionTypes.SET_CONSENT_DETAIL_QUESTIONNAIRE]: (state, action) => {
      return {
        ...state,
        questionnaire: action.questionnaire,
      };
    },
    [consentActionTypes.SET_CONSENT_DETAIL_QUESTIONNAIRE_RESPONSE]: (
      state,
      action,
    ) => {
      return {
        ...state,
        questionnaireResponse: action.questionnaireResponse,
      };
    },
  }),
};

export default combineReducers(reducers);
