import appActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';

export interface AppState {
  initializing?: boolean;
  init?: boolean;
}

const INITIAL_STATE: AppState = {
  initializing: true,
  init: false,
};

const reducer = createReducer<AppState>(INITIAL_STATE, {
  [appActionTypes.INITIALIZE_APP_START]: (state, action) => {
    return { ...state, ...action.app };
  },
  [appActionTypes.INITIALIZE_APP_END]: (state, action) => {
    return { ...state, ...action.app };
  },
});

export default reducer;
