import {
  DocumentUnclassified,
  Rotation,
  MaybeUnclassifiedClassification,
} from './types';
import urls from '../../../util/fhirResourceUrls';
import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import {
  ClassificationMetadataProperty,
  OCRSettings,
  PageDimensions,
} from '../../../types';
import { SectionMetadata } from '../types';

/**
 * 0-based index of the page within the document
 */
export type PageIndex = number;

/**
 * 1-based page number of the page within the document.  Textract represents page position by number
 */
export type PageNumber = number;

export const pageIndexFromComposition = (
  composition: fhir.Composition,
): PageIndex | null => {
  const targetTag = (composition?.meta?.tag || []).find(
    _ => _.system === 'http://lifeomic.com/ocr/documents/page-number',
  );
  if (!targetTag) return null;

  const parsedNumber = Number(targetTag.code);
  return isNaN(parsedNumber) ? null : parsedNumber;
};

export const getClassificationFromComposition = (
  composition: fhir.Composition,
): fhir.Coding | DocumentUnclassified => {
  const coding = composition?.type?.coding?.[0];

  return coding?.code ? coding : 'UNCLASSIFIED';
};

export const getCompositionImageFileId = (
  composition: fhir.Composition,
): string =>
  (composition?.extension || []).find(_ => _?.url === urls.pageImageId)
    ?.valueString;

export const getMaskedWordsFromComposition = (
  composition: fhir.Composition,
): string[] => {
  const targetExtension = composition?.extension?.find?.(
    _ => _.url === urls.maskedWordIds,
  );

  if (!targetExtension || !targetExtension.valueString) return [];

  const individualIds = targetExtension.valueString
    .split(',')
    .map(_ => _.trim())
    .filter(Boolean);

  return individualIds;
};

export const getCompositionSourceDocumentReferenceId = (
  composition: fhir.Composition,
) => {
  return (
    (composition?.relatesTo?.[0]?.targetReference?.reference || '')
      .split('/')
      .pop() || null
  );
};

export const getCompositionSourceText = (
  composition: fhir.Composition,
): string => {
  return composition.text?.div;
};

export const updateCompositionWithMaskedWords = (
  composition: fhir.Composition,
  maskedWordIds: string[],
): fhir.Composition => {
  const extensionsWithoutMaskedWords = (composition?.extension || []).filter(
    _ => _.url !== urls.maskedWordIds,
  );
  const newExtensions = [...extensionsWithoutMaskedWords];
  if (maskedWordIds?.length) {
    const cleanedValue = [...new Set(maskedWordIds.map(_ => _.trim()))].join(
      ',',
    );
    newExtensions.push({
      url: urls.maskedWordIds,
      valueString: cleanedValue,
    });
  }

  return {
    ...(composition || ({} as fhir.Composition)),
    extension: newExtensions,
  };
};

/**
 * Validates metadata values exist in config, adds empty values for properties not in config, and sorts values into config order
 */
const normalizeSectionMetadata = (
  metadata: SectionMetadata[],
  propertiesFromConfig: ClassificationMetadataProperty[],
) => {
  const labelSourceIndex: Record<string, number> = {};
  propertiesFromConfig.forEach(({ label }, index) => {
    labelSourceIndex[label] = index;
  });

  const configuredMetadataHash: Record<string, SectionMetadata> = {};
  metadata
    .filter(_ => labelSourceIndex[_.label] !== undefined) // filter out labels not configured for this classification
    .filter(_ => !!_.value)
    .forEach(_ => (configuredMetadataHash[_.label] = _));

  return propertiesFromConfig
    .map(_ => configuredMetadataHash[_.label] || { label: _.label, value: '' })
    .sort((m1, m2) => labelSourceIndex[m1.label] - labelSourceIndex[m2.label]);
};

const METADATA_SYSTEM_PREFIX =
  'http://lifeomic.com/ocr/documents/section-metadata/';

function systemToMetadataLabel(systemUrl: string): string | null {
  if (!systemUrl.startsWith(METADATA_SYSTEM_PREFIX)) return null;

  return systemUrl.replace(METADATA_SYSTEM_PREFIX, '').replace(/-/g, ' ');
}

function metadataLabelToSystem(systemUrl: string): string | null {
  if (!systemUrl) return null;

  return METADATA_SYSTEM_PREFIX + systemUrl.replace(/\s/g, '-');
}

function isMetadataExtension(extension: fhir.Extension): boolean {
  return extension.url.startsWith(METADATA_SYSTEM_PREFIX);
}

export const updateCompositionWithMetadata = (
  composition: fhir.Composition,
  metadata: SectionMetadata[],
  propertiesFromConfig: ClassificationMetadataProperty[],
): fhir.Composition => {
  const extensionsWithoutAnyMetadata = (composition.extension || []).filter(
    _ => !isMetadataExtension(_),
  );
  const normalizedMetadata = normalizeSectionMetadata(
    metadata,
    propertiesFromConfig,
  );
  const metadataExtensions = normalizedMetadata
    .filter(_ => !!_.value)
    .map<fhir.Extension>(_ => ({
      url: metadataLabelToSystem(_.label),
      valueString: _.value,
    }));
  return {
    ...composition,
    extension: [...extensionsWithoutAnyMetadata, ...metadataExtensions],
  };
};

export const getDefaultSectionMetadata = (
  propertiesFromConfig: ClassificationMetadataProperty[],
): SectionMetadata[] =>
  propertiesFromConfig.map(_ => ({ label: _.label, value: '' }));

export const getMetadataFromComposition = (
  composition: fhir.Composition,
  propertiesFromConfig: ClassificationMetadataProperty[],
): SectionMetadata[] => {
  const sectionMetadata = (composition.extension || [])
    .filter(isMetadataExtension)
    .map<SectionMetadata>(_ => ({
      label: systemToMetadataLabel(_.url),
      value: _.valueString,
    }));
  return normalizeSectionMetadata(sectionMetadata, propertiesFromConfig);
};

export const updateCompositionWithClassification = (
  composition: fhir.Composition,
  classification: MaybeUnclassifiedClassification,
): fhir.Composition => ({
  ...(composition || ({} as fhir.Composition)),
  type: {
    ...(composition?.type || {}),
    coding:
      classification === 'UNCLASSIFIED'
        ? undefined
        : [{ ...classification, userSelected: true } as fhir.Coding],
  },
});

export const updateCompositionWithRotation = (
  composition: fhir.Composition,
  rotation: Rotation,
): fhir.Composition => ({
  ...(composition || ({} as fhir.Composition)),
  extension: [
    ...(composition?.extension || []).filter(
      _ => _.url !== urls.manualPageRotation,
    ),
    {
      url: urls.manualPageRotation,
      valueInteger: rotation,
    },
  ],
});

export const updateCompositionWithRefDate = (
  composition: fhir.Composition,
  refDate: Date | null,
): fhir.Composition => {
  const copiedComposition = _cloneDeep(composition);
  if (copiedComposition.extension?.length) {
    copiedComposition.extension = copiedComposition.extension.filter(
      _ => _.url !== urls.manualDateAssigned,
    );
  }

  if (refDate) {
    copiedComposition.date = moment(refDate)
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();
    copiedComposition.extension = [
      ...(copiedComposition.extension || []),
      { url: urls.manualDateAssigned },
    ];
  } else {
    delete copiedComposition.date;
  }

  if (copiedComposition.extension?.length === 0) {
    delete copiedComposition.extension;
  }

  return copiedComposition;
};

/**
 * The ingestion process tries to predict the rotation of the document in the pipeline.
 * This value represents the rotation that should be done to correct the orientation
 */
export const getRotationPrediction = (
  composition: fhir.Composition,
): Rotation | null => {
  const targetExtension = composition?.extension?.find?.(
    _ => _.url === urls.ingestionRotationPrediction,
  );

  if (!targetExtension) return null;

  if (
    !targetExtension?.valueInteger ||
    ![90, 180, 270].includes(targetExtension.valueInteger)
  )
    return 0;

  return targetExtension.valueInteger as Rotation;
};

/**
 * Reads the individual section review status from a composition.  It will also validate
 * the review status against the given ocr settings to ensure the status is still valid.
 */
export const getSectionReviewStatus = (
  composition: fhir.Composition,
  ocrSettings: OCRSettings,
) => {
  if (!ocrSettings?.reviewStages?.length) return null;

  const rawReviewStage = (composition?.extension || []).find(
    _ => _.url === urls.documentSectionReviewStage,
  )?.valueString;

  if (!rawReviewStage) return null;

  if (!ocrSettings.reviewStages.includes(rawReviewStage)) return null;

  return rawReviewStage;
};

export const clearSectionReviewStatus = (
  composition: fhir.Composition,
): fhir.Composition => {
  return {
    ...composition,
    extension: (composition?.extension || []).filter(
      _ => _.url !== urls.documentSectionReviewStage,
    ),
  };
};

export const updateSectionReviewStatus = (
  composition: fhir.Composition,
  reviewStatus: string,
): fhir.Composition => {
  const withoutExisting = clearSectionReviewStatus(composition);
  if (!reviewStatus) {
    return withoutExisting;
  }

  return {
    ...withoutExisting,
    extension: [
      ...(withoutExisting?.extension || []),
      {
        url: urls.documentSectionReviewStage,
        valueString: reviewStatus,
      },
    ],
  };
};

/**
 * A user can update a document's rotation.  This retrieves the per-page manual override
 */
export const getManualRotation = (
  composition: fhir.Composition,
): Rotation | null => {
  const targetExtension = composition?.extension?.find?.(
    _ => _.url === urls.manualPageRotation,
  );

  if (!targetExtension) return null;

  if (
    !targetExtension?.valueInteger ||
    ![90, 180, 270].includes(targetExtension.valueInteger)
  )
    return 0;

  return targetExtension.valueInteger as Rotation;
};

type ParsedDateCache = { [key: string]: Date };
const cachedParsedDates: ParsedDateCache = {};
export const getRefDateFromComposition = (
  composition: fhir.Composition,
): Date | null => {
  if (!composition?.date) return null;

  try {
    if (!cachedParsedDates[composition.date]) {
      const asMoment = moment(composition.date);

      if (!asMoment.isValid())
        throw new Error(
          `fhir resource had an invalid composition date: ${composition.date}`,
        );

      cachedParsedDates[composition.date] = asMoment.toDate();
    }

    return cachedParsedDates[composition.date];
  } catch (e) {
    console.error(e);
    console.error(
      'Recieved a datetime value with an invalid date: ' + composition.date,
    );
    return null;
  }
};

export const defaultDimensions: PageDimensions = {
  width: 1280,
  height: (11 / 8.5) * 1280,
};

/**
 * Tries to parse page dimensions from composition.  Returns null if dimensions are invalid or do not exist
 */
const tryGetPageDimensions = (
  composition: fhir.Composition,
): PageDimensions | null => {
  if (!composition) return null;

  const targetExension = composition?.extension?.find?.(
    _ => _.url === urls.pageAspectRatio,
  );

  if (!targetExension?.valueString) return null;

  // format: 850 x 1100
  const parts = targetExension.valueString
    .split('x')
    .map(_ => _.trim())
    .map(Number)
    .filter(_ => !isNaN(_));

  if (parts.length !== 2) {
    console.error(
      `Recieved a page dimension in an invalid format: "${targetExension.valueString}"`,
    );

    return null;
  }

  return {
    width: parts[0],
    height: parts[1],
  };
};

export const pageDimensionsExistInComposition = (
  composition: fhir.Composition,
) => {
  return tryGetPageDimensions(composition) !== null;
};

/**
 * Retrieve page dimensions if they exist or defaults the dimensions if not
 */
export const getPageDimensions = (
  composition: fhir.Composition,
): PageDimensions => {
  return tryGetPageDimensions(composition) || defaultDimensions;
};
