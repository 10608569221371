import { UserPatientsState } from './reducer';
import { selectActiveProjectId } from '../projectPicker/selectors';
import { createSelector } from 'reselect';
import get from 'lodash/get';

const emptyArray = [] as any[];

const projectFromPatient = (patient: any) => {
  const tags = get(patient, 'meta.tag', []) as any[];
  const datasetTag =
    tags.find(
      (tag = {}) => tag.system === 'http://lifeomic.com/fhir/dataset',
    ) || {};
  return datasetTag.code;
};

export const selectUserPatientsState = (state: any): UserPatientsState =>
  state.userPatients;

export const selectResponse = (state: any) =>
  selectUserPatientsState(state).response || emptyArray;

export const selectPatientsFromResponse = createSelector(
  selectResponse,
  response =>
    (response.entry || [])
      .map((entry: any = {}) => entry.resource)
      .filter((entry: any) => !!entry),
);

export const selectProjectsUserIsPatientOf = createSelector(
  selectPatientsFromResponse,
  (responsePatients = []) => responsePatients.map(projectFromPatient),
);

export const selectExplicitelyAssignedUserPatient = createSelector(
  selectUserPatientsState,
  userPatientsState => userPatientsState.explicitelyAssignedUserPatient,
);

export const selectIsFetching = (state: any) =>
  selectUserPatientsState(state).isFetching;

export const selectError = (state: any) => selectUserPatientsState(state).error;

export const selectLastFetchAttempt = (state: any) =>
  selectUserPatientsState(state).lastFetchAttempt;

export const selectUserPatient = createSelector(
  selectPatientsFromResponse,
  selectActiveProjectId,
  selectExplicitelyAssignedUserPatient,
  (patients, activeProjectId, explicitelyAssignedUserPatient) => {
    if (explicitelyAssignedUserPatient) return explicitelyAssignedUserPatient;

    if (!activeProjectId || patients.length === 0) return null;

    const mappedEntries = patients
      .map((patient: any) => {
        return {
          dataset: projectFromPatient(patient),
          patient: patient,
        };
      })
      .filter((entry: any) => !!entry.dataset);

    const targetEntry = mappedEntries.find(
      (entry: any) => entry.dataset === activeProjectId,
    );
    return get(targetEntry, 'patient', null);
  },
);

export const selectUserPatientId = createSelector(
  selectUserPatient,
  userPatient => get(userPatient, 'id', null),
);

export const selectUserHasAnAssociatedPatient = createSelector(
  selectUserPatientId,
  usersPatientId => usersPatientId !== null,
);
