import notificationsActionTypes from './actionTypes';

export interface NotificationOptions {
  statusType?: 'info' | 'warning' | 'error' | 'success';
}

function addNotification(message: string, options: NotificationOptions) {
  return {
    type: notificationsActionTypes.NOTIFICATIONS_QUEUE_NOTIFICATION,
    message,
    options,
  };
}

function removeNotification() {
  return {
    type: notificationsActionTypes.NOTIFICATIONS_DEQUEUE_NOTIFICATION,
  };
}

export default {
  addNotification,
  removeNotification,
};
