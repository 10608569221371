import actionTypes from './actionTypes';
import actions from './actions';

import {
  createAsyncRequestReducer,
  AsyncRequestReducerOptions,
  FetchState,
} from '../../util/fetchReducers';
import { Project } from '../../../common/types/Projects';

export interface ProjectPickerState extends Partial<FetchState> {
  activeProject: string | null;
  projectList: Project[];
}

type SetActiveProjectAction = ReturnType<typeof actions.setActiveProject>;
type UnselectProjectAction = ReturnType<typeof actions.unselectProject>;
type HydrateActiveProjectAction = ReturnType<
  typeof actions.hydrateActiveProject
>;

const options: AsyncRequestReducerOptions<ProjectPickerState> = {
  initialStateProps: {
    activeProject: null,
    projectList: [],
  },
  asyncReducers: {
    success: (state, action) => ({
      ...state,
      projectList: action.response.items,
      error: null,
      activeProject: state.activeProject || null,
    }),
    failure: state => ({
      ...state,
      projectList: [],
    }),
  },
  reducers: {
    [actionTypes.SET_ACTIVE_PROJECT]: (
      state,
      action: SetActiveProjectAction,
    ) => {
      return {
        ...state,
        activeProject: action.id,
      };
    },
    [actionTypes.UNSELECT_PROJECT]: (state, action: UnselectProjectAction) => {
      // Find the first project that does NOT have the given id
      const head = state.projectList.find(x => x.id !== action.id);
      return {
        ...state,
        activeProject: head?.id ?? null,
      };
    },
    [actionTypes.HYDRATE_ACTIVE_PROJECT]: (
      state,
      action: HydrateActiveProjectAction,
    ) => {
      if (!action.activeProject) return state;
      if (state.projectList.find(v => v.id === action.activeProject)) {
        return {
          ...state,
          activeProject: action.activeProject,
        };
      } else return state;
    },
  },
};

export default createAsyncRequestReducer(actionTypes.FETCH_PROJECTS, options);
