import actionTypes from './actionTypes';
import actions from './actions';

import {
  createAsyncRequestReducer,
  AsyncRequestReducerOptions,
  FetchState,
} from '../../util/fetchReducers';

export interface CohortPickerState extends Partial<FetchState> {
  activeProjectCohort: string | null;
  projectCohortList: any[];
  /**
   * What project this cohort list was requested for.  This helps determine if a cohort list is invalidated when a
   * new project is selected.
   */
  cohortListProject: string | null;
  /**
   * What cohorts were previously active per project.  This allows us to remember which cohort was chosen
   * for each project.
   */
  lastUsedCohortByProject: Record<string, string>;
}

type SetActiveCohortAction = ReturnType<typeof actions.setActiveProjectCohort>;
type HydrateActiveCohortAction = ReturnType<
  typeof actions.hydrateActiveProjectCohort
>;
type SetCohortListProjectAction = ReturnType<
  typeof actions.setCohortListProject
>;

const options: AsyncRequestReducerOptions<CohortPickerState> = {
  initialStateProps: {
    activeProjectCohort: null,
    projectCohortList: [],
    cohortListProject: null,
    lastUsedCohortByProject: {},
  },
  asyncReducers: {
    success: (state, action) => ({
      ...state,
      projectCohortList: action.response.items,
      error: null,
      activeProjectCohort: state.activeProjectCohort || null,
      lastUsedCohortByProject: state.lastUsedCohortByProject || {},
    }),
    failure: state => ({
      ...state,
      projectCohortList: [],
    }),
  },
  reducers: {
    [actionTypes.SET_COHORT_LIST_PROJECT]: (
      state,
      action: SetCohortListProjectAction,
    ) => {
      const lastUsedCohortByProject = { ...state.lastUsedCohortByProject };
      const activeProjectCohort =
        action.cohortListProject in lastUsedCohortByProject
          ? lastUsedCohortByProject[action.cohortListProject]
          : null;
      return {
        ...state,
        cohortListProject: action.cohortListProject,
        activeProjectCohort,
      };
    },
    [actionTypes.SET_ACTIVE_PROJECT_COHORT]: (
      state,
      action: SetActiveCohortAction,
    ) => {
      const lastUsedCohortByProject = { ...state.lastUsedCohortByProject };
      lastUsedCohortByProject[state.cohortListProject] = action.id;

      return {
        ...state,
        activeProjectCohort: action.id,
        lastUsedCohortByProject,
      };
    },
    [actionTypes.HYDRATE_ACTIVE_PROJECT_COHORT]: (
      state,
      action: HydrateActiveCohortAction,
    ) => {
      if (!action.activeProjectCohort) {
        return {
          ...state,
          lastUsedCohortByProject: action.lastUsedCohortByProject,
        };
      }

      const cohortIsAllowed = (id: string) =>
        state.projectCohortList.find(v => v.id === id);

      const persistedCohortAllowed = cohortIsAllowed(
        action.lastUsedCohortByProject[state.cohortListProject],
      );

      if (cohortIsAllowed(action.activeProjectCohort)) {
        return {
          ...state,
          ...action,
        };
      } else if (persistedCohortAllowed) {
        return {
          ...state,
          activeProjectCohort:
            action.lastUsedCohortByProject[state.cohortListProject],
          lastUsedCohortByProject: action.lastUsedCohortByProject,
        };
      } else
        return {
          ...state,
          lastUsedCohortByProject: action.lastUsedCohortByProject,
        };
    },
  },
};

export default createAsyncRequestReducer(
  actionTypes.FETCH_PROJECT_COHORTS,
  options,
);
