import * as React from 'react';
import './styles/styles.global.css';
import { render } from 'react-dom';
import Root from './components/Root';
import lifeOmicHelpers from './util/lifeOmicHelpers';
import store from './redux/store';

window.LifeOmic = {
  util: lifeOmicHelpers(store),
  buildManifest: process.env.BUILD_MANIFEST || {},
};

function renderApp() {
  render(<Root store={store} />, document.getElementById('root'));
}

renderApp();
