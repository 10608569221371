import { PageRotation, Rotation } from './types';
import { TextractGeometryObject } from '../types';
import CompositionCacheHelper from './CompositionCacheHelper';
import * as compositionUtils from './util';

const rotateGeometryCW = (
  geometry: TextractGeometryObject,
): TextractGeometryObject => {
  return {
    BoundingBox: {
      Height: geometry.BoundingBox.Width,
      Left: 1 - (geometry.BoundingBox.Top + geometry.BoundingBox.Height), // prev bottom
      Top: geometry.BoundingBox.Left,
      Width: geometry.BoundingBox.Height,
    },
    Polygon: (geometry.Polygon || []).map(({ X, Y }) => ({ X: 1 - Y, Y: X })), // (x, y) => (-y, x)
  };
};

const rotateCWNTimes = (geometry: TextractGeometryObject, nTimes: number) => {
  let returnValue = geometry;
  for (let i = 0; i < nTimes; i++) {
    returnValue = rotateGeometryCW(returnValue);
  }
  return returnValue;
};

type RotationUpdate = {
  pageIndex: number;
  desiredRotation: Rotation;
};

export const rotate = (
  geometry: TextractGeometryObject,
  from: Rotation,
  to: Rotation,
): TextractGeometryObject => {
  if (from === to) return geometry;

  const safeTo = to < from ? to + 360 : to;
  const turnCount = (safeTo - from) / 90;
  const safeTurnCount = Math.floor(turnCount); // should never happen, but just to be safe
  return rotateCWNTimes(geometry, safeTurnCount);
};

export default class DocumentRotationHelper extends CompositionCacheHelper {
  private _pageRotations: PageRotation[];
  private _rotationKey: string;

  constructor(compositions: fhir.Composition[]) {
    super(compositions);
    this._pageRotations = [];
    (compositions || []).forEach(_ => {
      const targetPageIndex = compositionUtils.pageIndexFromComposition(_);
      this._pageRotations[targetPageIndex] = {
        pageIndex: targetPageIndex,
        predictedRotation: compositionUtils.getRotationPrediction(_),
        manualRotation: compositionUtils.getManualRotation(_),
      } as PageRotation;
    });
  }

  /**
   * Rotates the a page object's geometry to the current page rotation
   */
  rotateGeometry(
    pageIndex: number,
    geometry: TextractGeometryObject,
  ): TextractGeometryObject {
    return rotate(geometry, 0, this.getRotationToRender(pageIndex));
  }

  getPageRotationForPage(pageIndex: number) {
    return this._pageRotations[pageIndex];
  }

  /**
   * Evaluates rotation predication and manual override to calculate
   * the rotation a page should be rendered at
   */
  getRotationToRender(pageIndex: number): Rotation {
    const targetRotation = this.getPageRotationForPage(pageIndex);

    if (!targetRotation) return 0;

    // if override exists, always use that
    if (targetRotation.manualRotation !== null) {
      return targetRotation.manualRotation;
    } else if (targetRotation.predictedRotation !== null) {
      return targetRotation.predictedRotation;
    } else {
      return 0;
    }
  }

  getCWRotation(pageIndex: number): Rotation {
    const targetRotation = this.getRotationToRender(pageIndex) + 90;
    return targetRotation >= 360 ? 0 : (targetRotation as Rotation);
  }

  getCCWRotation(pageIndex: number): Rotation {
    const targetRotation = this.getRotationToRender(pageIndex) - 90;
    return targetRotation < 0 ? 270 : (targetRotation as Rotation);
  }

  /**
   * Does a composition have any metadata about the original pdf dimensions.
   * Legacy data will not have this metdata
   */
  getPageDimensionsExistForComposition(pageIndex: number) {
    const targetComposition = this.getPageComposition(pageIndex);
    return compositionUtils.pageDimensionsExistInComposition(targetComposition);
  }

  /**
   * Page dimenions that do not account for the currently configured rotation
   */
  getOriginalPageDimensions(pageIndex: number) {
    const targetComposition = this.getPageComposition(pageIndex);
    return compositionUtils.getPageDimensions(targetComposition);
  }

  /**
   * Page dimensions where the dimensions are properly rotated from the original dimensions based
   * on the current rotation
   */
  getRotatedPageDimensions(pageIndex: number) {
    const dimensions = this.getOriginalPageDimensions(pageIndex);
    const rotation = this.getRotationToRender(pageIndex);
    const isLandscape = rotation === 90 || rotation === 270;

    return {
      height: isLandscape ? dimensions.width : dimensions.height,
      width: isLandscape ? dimensions.height : dimensions.width,
    };
  }

  /**
   * Ingestion gives us exact dimensions.  If ingestion is not yet complete
   * we compute partial dimensions for pdf js and it renders the other dimensions to scale
   */
  getAproximatedPDFJsDimensions(pageIndex: number, originalWidth: number) {
    const rotation = this.getRotationToRender(pageIndex);
    const isLandscape = rotation === 90 || rotation === 270;

    return {
      height: isLandscape ? originalWidth : undefined,
      width: isLandscape ? undefined : originalWidth,
    };
  }

  updateCompositionsWithPageRotations(
    rotationUpdates: RotationUpdate[],
  ): fhir.Composition[] {
    return this.compositions
      .map(cacheItem => {
        const cacheItemPage =
          compositionUtils.pageIndexFromComposition(cacheItem);
        const targetUpdatedRotation = rotationUpdates.find(
          _ => cacheItemPage === _.pageIndex,
        );

        if (!targetUpdatedRotation) return null;

        return compositionUtils.updateCompositionWithRotation(
          cacheItem,
          targetUpdatedRotation.desiredRotation,
        );
      })
      .filter(Boolean);
  }

  /**
   * uniquely identify the current rotation state
   */
  get rotationKey() {
    if (!this._rotationKey) {
      const pageCount = this.maxPageNumber;
      const individualKeys: string[] = [];
      for (let index = 0; index < pageCount; index++) {
        individualKeys.push(
          `page:${pageCount}+rotation:${this.getRotationToRender(index)}`,
        );
      }
      this._rotationKey = individualKeys.join(';');
    }
    return this._rotationKey;
  }
}
