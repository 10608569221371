import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions, userSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/user';
import { appActions, appSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/app';
import { authSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/auth';
import urls from '../util/urls';

const useInitialization = (): boolean => {
  const dispatch = useDispatch();

  const app = useSelector(appSelectors.selectAppState);
  const auth = useSelector(authSelectors.selectAuthState);
  const user = useSelector(userSelectors.selectUserState);

  const [initStarted, setInitStarted] = React.useState(false);
  const [initCompleted, setInitCompleted] = React.useState(false);

  const isInitializing = initStarted && !initCompleted;
  const completeInit = initStarted && !initCompleted && user.response;

  // App
  React.useEffect(() => {
    if (!app.init) {
      setInitStarted(true);
      dispatch(appActions.initializeApp({ skipCookieProcessing: true }));
    }
  }, [app.init, dispatch]);

  // Auth
  React.useEffect(() => {
    if (app.init && !auth.isAuthenticated) {
      window.location.href = urls.externalApps.login(window.location.href);
    }
  }, [app.init, auth.isAuthenticated]);

  // User
  React.useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(userActions.fetchUser());
    }
  }, [auth.isAuthenticated, dispatch]);

  // Init complete
  React.useEffect(() => {
    if (completeInit) {
      setInitCompleted(true);
    }
  }, [completeInit]);

  return isInitializing;
};

export default useInitialization;
