import _isNil from 'lodash/isNil';
import { ViewerState, PersistedViewerDocState } from './types';
import { PendingViewerFocus } from '../../../types';
import DocumentRotationHelper from '../DocumentCompositions/DocumentRotationHelper';
import { ResourceTypes } from '../DataTables/Suggestions/useCreatedSuggestionHash';

export const allResourceTypes: ResourceTypes[] = [
  'Condition',
  'MedicationAdministration',
  'Observation',
  'Procedure',
];

export const buildDefaultState = (
  docRefId: string = null,
  pendingViewerFocus: PendingViewerFocus | null = null,
  persistedState: PersistedViewerDocState | null = null,
): ViewerState => ({
  projectClassifications: [],
  docRefId,
  viewerDocs: null,
  compositionCache: null,
  isSpellCheckActive: !!persistedState?.isSpellCheckActive,
  isMaskActive: _isNil(persistedState?.isMaskActive)
    ? true
    : persistedState?.isMaskActive,
  isDocumentDatesActive: false,
  isDocumentDetailsModeActive: false,
  isExtractedSummaryModeActive: false,
  pendingViewerFocus,
  boxSelection: {
    selectedLines: [],
    selectedWords: [],
    selectionElementType: 'word',
    isSelecting: false,
  },
  zoomMultiplyer: persistedState?.zoomMultiplyer || 1,
  aspectRatio: null,
  analyze: {
    isAnalyzeModeActive: false,
    analyzeInput: null,
    focusedAnalyzeResource: null,
    analyzedResourcesSearchText: null,
  },
  search: {
    foundPhrases: [],
    focusedPhrase: null,
    showResultList: false,
  },
  pickList: {
    pickListItems: [],
    isFocused: false,
  },
  rawTextractData: [],
  textractDataProcessing: {
    isProcessing: false,
    processedData: {
      pageRecords: [],
      parseOptions: {
        maskedWords: { status: 'mask-pending' },
        applySpellCheck: false,
        documentRotations: new DocumentRotationHelper([]),
      },
    },
  },
  sourceDoc: 'original',
  renderedPageCount: null,
  ingestedSuggestions: null,
  isIngestionSuggestionsActive: false,
  displayedIngestionSuggetionTypes:
    persistedState?.displayedIngestionSuggetionTypes || allResourceTypes,
  reportExtractors: [],
  displayComprehendSuggestions: true,
  disabledSearchLists: [],
  dataMode: 'document',
  isQuickNavOpen: !!persistedState?.isQuickNavOpen,
  highlightNegatedSuggestions: false,
  organize: {
    selectionRange: null,
    groupingMode: 'stack',
    postUpdatePageScrollIndex: null,
    collapsedSectionKeys: [],
  },
  organizeZoom: persistedState?.organizeZoom || 1,
  organizeDateHighlight: persistedState?.organizeDateHighlight || false,
  organizeDateSuggest: persistedState?.organizeDateSuggest || false,
  compositionCacheUpdateCount: 0,
  organizeHideConfirmedPages:
    persistedState?.organizeHideConfirmedPages || false,
  ocrSettings: null,
  compositionDuplicates: {
    compositionDuplicates: [],
    focusedCompositionDuplicates: null,
  },
  hoverZoom: {
    isHoverZoomActive: false,
  },
  pagesInView: new Set<number>(),
  organizePagesInView: new Set<number>(),
  query: {
    isQueryStateActive: false,
    focusedQueryId: null,
  },
});

const defaultState: ViewerState = buildDefaultState();

export default defaultState;
