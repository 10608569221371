import actionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';

export interface MaskedModeState {
  active: boolean;
}

const INITIAL_STATE: MaskedModeState = {
  active: true,
};

const reducer = createReducer(INITIAL_STATE, {
  [actionTypes.SET_MASKED_MODE]: (state, action) => {
    return { ...state, active: action.active };
  },
});

export default reducer;
