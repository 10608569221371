import dialogActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';

const INITIAL_STATE = {
  dialogs: {},
};

const reducer = createReducer(INITIAL_STATE, {
  [dialogActionTypes.DIALOG_TOGGLE]: (state, action) => {
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        [action.dialogKey]: action.isOpen,
      },
    };
  },
});

export default reducer;
