import { asyncActionSuffixes } from './constants';
import { createReducer, ReducerMap, Reducer } from './createReducer';

export interface AsyncRequestReducerOptions<State> {
  asyncReducers?: {
    root?: Reducer<FetchState<State>>;
    start?: Reducer<FetchState<State>>;
    success?: Reducer<FetchState<State>>;
    failure?: Reducer<FetchState<State>>;
    reset?: Reducer<FetchState<State>>;
  };
  reducers?: ReducerMap<FetchState<State>>;
  initialStateProps?: State;
}

export type FetchState<S extends Partial<FetchState> = {}> = {
  isFetching: boolean;
  lastFetchAttempt: Date;
  error: any;
  response: S['response'];
  errorResponse?: any;
} & S;

const initialFetchState: FetchState<{}> = {
  isFetching: false,
  lastFetchAttempt: null,
  error: null,
  response: null,
  errorResponse: null,
};

function reduceFetchRoot<State>(
  reducer: Reducer<FetchState<State>>,
): Reducer<FetchState<State>> {
  return (state, action) => {
    return typeof reducer === 'function' ? reducer(state, action) : state;
  };
}

function reduceFetchStart<State>(
  reducer: Reducer<FetchState<State>>,
): Reducer<FetchState<State>> {
  return (state, action) => {
    const newState: FetchState<State> = {
      ...(state as any),
      isFetching: true,
      error: null,
    };
    return typeof reducer === 'function' ? reducer(newState, action) : newState;
  };
}

function reduceFetchSuccess<State>(
  reducer: Reducer<FetchState<State>>,
): Reducer<FetchState<State>> {
  return (state, action) => {
    const newState: FetchState<State> = {
      ...(state as any),
      isFetching: false,
      error: null,
      response: action.response,
      lastFetchAttempt: new Date(),
    };
    delete newState.errorResponse;
    return typeof reducer === 'function' ? reducer(newState, action) : newState;
  };
}

function reduceFetchError<State>(
  reducer: Reducer<FetchState<State>>,
): Reducer<FetchState<State>> {
  return (state, action) => {
    const newState: FetchState<State> = {
      ...(state as any),
      response: null,
      isFetching: false,
      error: action.error,
      errorResponse: action.response,
      lastFetchAttempt: new Date(),
    };
    return typeof reducer === 'function' ? reducer(newState, action) : newState;
  };
}

function reduceFetchReset<State>(
  reducer: Reducer<FetchState<State>>,
): Reducer<FetchState<State>> {
  return (state, action) => {
    const newState = {
      ...state,
      ...initialFetchState,
    };
    return typeof reducer === 'function' ? reducer(newState, action) : newState;
  };
}

function createAsyncRequestReducer<State>(
  baseActionType: string,
  options: AsyncRequestReducerOptions<State> = {},
) {
  const { reducers = {}, asyncReducers = {}, initialStateProps = {} } = options;
  const handlers: ReducerMap<FetchState<State>> = {
    [baseActionType]: reduceFetchRoot(asyncReducers.root),
    [baseActionType + asyncActionSuffixes.start]: reduceFetchStart(
      asyncReducers.start,
    ),
    [baseActionType + asyncActionSuffixes.success]: reduceFetchSuccess(
      asyncReducers.success,
    ),
    [baseActionType + asyncActionSuffixes.failure]: reduceFetchError(
      asyncReducers.failure,
    ),
    [baseActionType + asyncActionSuffixes.reset]: reduceFetchReset(
      asyncReducers.reset,
    ),
    ...reducers,
  };

  return createReducer(
    createInitialState<FetchState<State>>(initialStateProps),
    handlers,
  );
}

function createInitialState<S>(props: any = {}): FetchState<S> {
  return {
    ...initialFetchState,
    ...props,
  };
}

export {
  initialFetchState,
  reduceFetchRoot,
  reduceFetchStart,
  reduceFetchSuccess,
  reduceFetchError,
  reduceFetchReset,
  createAsyncRequestReducer,
  createInitialState,
};
