import authActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';
import _get from 'lodash/get';

export interface AuthState {
  isAuthenticated: boolean;
  isProcessing: boolean;
  expiresAt: number;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  jwtPayload: string;
  email: string;
  oauthClientId: string;
  oauth: {
    isFetching: boolean;
    response: any;
    lastFetchAttempt: Date;
    error: any;
    operation: any;
  };
  error?: {
    error?: Error;
    description?: string;
  };
  cookieState?: {
    isLoggedInWithCookie?: boolean;
    error?: Error;
    clientId?: string;
  };
}

const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  isProcessing: false,
  expiresAt: null,
  accessToken: null,
  refreshToken: null,
  idToken: null,
  jwtPayload: null,
  email: null,
  oauthClientId: null,
  oauth: {
    isFetching: false,
    response: null,
    lastFetchAttempt: null,
    error: null,
    operation: null,
  },
  cookieState: {
    isLoggedInWithCookie: false,
    error: null,
    clientId: null,
  },
};

const reducer = createReducer(INITIAL_STATE, {
  [authActionTypes.HYDRATE_AUTH]: (state, action) => {
    return { ...state, ...action.auth };
  },
  [authActionTypes.AUTH_SUCCESS]: (state, action) => {
    return { ...state, ...action.auth };
  },
  [authActionTypes.PROCESS_AUTH]: (state, action) => {
    return { ...state, ...action.auth };
  },
  [authActionTypes.PROCESS_AUTH_START]: (state, action) => {
    return { ...state, ...action.auth };
  },
  [authActionTypes.PROCESS_AUTH_END]: (state, action) => {
    return { ...state, ...action.auth };
  },
  [authActionTypes.PROCESS_AUTH_ERROR]: (_state, action) => {
    return {
      ...INITIAL_STATE,
      ...{
        error: action.error,
      },
    };
  },
  [authActionTypes.AUTH_CLEAR_ERROR]: state => {
    return { ...state, error: null };
  },
  [authActionTypes.SET_OAUTH_CLIENT_ID]: (state, action) => {
    return { ...state, oauthClientId: _get(action, 'clientId') };
  },
  [authActionTypes.INITIATE_LOGOUT]: state => ({ ...state }),
  [authActionTypes.EXCHANGE_ACCESS_TOKEN]: state => state,
  [authActionTypes.EXCHANGE_ACCESS_TOKEN_START]: state => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: true,
        error: null,
        operation: authActionTypes.EXCHANGE_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.EXCHANGE_ACCESS_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: false,
        error: false,
        response: action.response,
        lastFetchAttempt: new Date(),
        operation: authActionTypes.EXCHANGE_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.EXCHANGE_ACCESS_TOKEN_ERROR]: (state, action) => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: false,
        error: action.error,
        response: null,
        lastFetchAttempt: new Date(),
        operation: authActionTypes.EXCHANGE_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.REFRESH_ACCESS_TOKEN]: state => state,
  [authActionTypes.REFRESH_ACCESS_TOKEN_START]: state => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: true,
        error: null,
        operation: authActionTypes.REFRESH_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: false,
        error: false,
        response: action.response,
        lastFetchAttempt: new Date(),
        operation: authActionTypes.REFRESH_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.REFRESH_ACCESS_TOKEN_ERROR]: (state, action) => {
    return {
      ...state,
      oauth: {
        ...state.oauth,
        isFetching: false,
        error: action.error,
        response: null,
        lastFetchAttempt: new Date(),
        operation: authActionTypes.REFRESH_ACCESS_TOKEN,
      },
    };
  },
  [authActionTypes.EXCHANGE_COOKIE_SUCCESS]: (state, action) => {
    return {
      ...state,
      cookieState: {
        isLoggedInWithCookie: true,
        error: null,
        ...action.cookieState,
      },
    };
  },
  [authActionTypes.EXCHANGE_COOKIE_ERROR]: (state, action) => {
    return {
      ...state,
      cookieState: {
        isLoggedInWithCookie: false,
        error: action.error,
      },
    };
  },
  [authActionTypes.REFRESH_COOKIE_SUCCESS]: (state, action) => {
    return {
      ...state,
      cookieState: {
        isLoggedInWithCookie: true,
        error: null,
        ...action.cookieState,
      },
    };
  },
  [authActionTypes.REFRESH_COOKIE_ERROR]: (state, action) => {
    return {
      ...state,
      cookieState: {
        isLoggedInWithCookie: false,
        error: action.error,
      },
    };
  },
  [authActionTypes.RESET_COOKIE]: state => {
    return {
      ...state,
      cookieState: {
        isLoggedInWithCookie: false,
        error: null,
        clientId: null,
      },
    };
  },
});

export default reducer;
