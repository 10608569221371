import { combineReducers } from 'redux';
import { createAsyncRequestReducer } from '../../util/fetchReducers';
import actionTypes from './actionTypes';

export const reducers = {
  fetchEvaluatedPolicy: createAsyncRequestReducer(
    actionTypes.FETCH_EVALUATED_POLICY,
  ),
};

export default combineReducers(reducers);
