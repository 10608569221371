import base64url from 'base64url/dist/base64url';

export function parseAuthResponse(response: any) {
  if (!response) return null;
  return {
    accessToken: response.access_token || null,
    idToken: response.id_token || null,
    expiresAt: response.expires_in
      ? new Date().getTime() + parseInt(response.expires_in, 10) * 1000
      : null,
    error: response.error || null,
    errorDescription: response.error ? response.error.toString() : null,
    refreshToken: response.refresh_token || null,
  };
}

export function getJwtPayload(jwt: string = ''): Record<string, any> {
  const jwtParts = jwt.split('.');
  if (jwtParts.length !== 3) {
    throw new Error('Invalid JWT');
  }
  return JSON.parse(base64url.decode(jwtParts[1]));
}
