import { overrides as componentOverrides } from './overrides';
import { createTheme } from '@lifeomic/chroma-react/styles';

export const theme = createTheme({
  components: componentOverrides,
  typography: {
    fontFamily: '"Work Sans", "Roboto", sans-serif',
    // Add Work Sans as secondary font as well so Chroma's default
    // secondary font is not used anywhere in this app
    fontFamilySecondary: '"Work Sans", "Roboto", sans-serif',
  },
});
