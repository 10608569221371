import { createPersistMiddleware } from '../../../../../redux/middleware/persistMiddleware';
import actionTypes from './actionTypes';
import { AnyAction } from 'redux';
import { ViewerDocsState, PersistedViewerDocState } from './types';

/**
 * Action types that will trigger an update to the persisted state
 */
const ocrViewerPeristActionTypes = [
  actionTypes.OCR_SET_ZOOM,
  actionTypes.OCR_SET_IS_SPELL_CHECK_ACTIVE,
  actionTypes.OCR_SET_IS_MASK_ACTIVE,
  actionTypes.OCR_SET_DISPLAYED_INGESTION_SUGGESTION_TYPES,
  actionTypes.OCR_SET_IS_QUICK_NAV_OPEN,
  actionTypes.OCR_SET_ORGANIZE_ZOOM,
  actionTypes.OCR_SET_ORGANIZE_DATE_HIGHLIGHTS,
  actionTypes.OCR_SET_ORGANIZE_DATE_SUGGEST,
  actionTypes.OCR_SET_ORGANIZE_HIDE_CONFIRMED_PAGES,
];

/**
 * Once an action triggers the state update, tell the persist middleware
 * what portion of the state should go into local storage
 */
const extractPersistedPortionOfState = (
  store: ViewerDocsState | any,
  action: AnyAction,
): PersistedViewerDocState | any => {
  if (!ocrViewerPeristActionTypes.includes(action.type)) return store;

  return {
    isSpellCheckActive: !!(store as ViewerDocsState)?.isSpellCheckActive,
    isMaskActive: !!(store as ViewerDocsState)?.isMaskActive,
    zoomMultiplyer: (store as ViewerDocsState)?.zoomMultiplyer || 1,
    displayedIngestionSuggetionTypes: (store as ViewerDocsState)
      ?.displayedIngestionSuggetionTypes || [
      'Condition',
      'MedicationAdministration',
      'Observation',
      'Procedure',
    ],
    isQuickNavOpen: !!(store as ViewerDocsState)?.isQuickNavOpen,
    organizeZoom: (store as ViewerDocsState)?.organizeZoom || 1,
    organizeDateHighlight:
      (store as ViewerDocsState)?.organizeDateHighlight || false,
    organizeDateSuggest:
      (store as ViewerDocsState)?.organizeDateSuggest || false,
    organizeHideConfirmedPages:
      (store as ViewerDocsState)?.organizeHideConfirmedPages || false,
  } as PersistedViewerDocState;
};

export default createPersistMiddleware(
  [
    {
      actionTypes: ocrViewerPeristActionTypes,
      reducer: 'ocrViewer',
      storage: 'localStorage',
    },
  ],
  extractPersistedPortionOfState,
);
