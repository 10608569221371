import groupActionTypes from './actionTypes';
import { createAsyncRequestReducer } from '../../util/fetchReducers';
import { combineReducers } from 'redux';

export const reducers = {
  userGroups: createAsyncRequestReducer(groupActionTypes.FETCH_USER_GROUPS),
  queryAccountGroups: createAsyncRequestReducer(
    groupActionTypes.FETCH_ACCOUNT_GROUPS,
  ),
  createGroup: createAsyncRequestReducer(groupActionTypes.CREATE_GROUP),
  updateGroup: createAsyncRequestReducer(groupActionTypes.UPDATE_GROUP),
  deleteGroup: createAsyncRequestReducer(groupActionTypes.DELETE_GROUP),
};

export default combineReducers(reducers);
