export default {
  /** The document reference used to generate the derived resource */
  sourceDocumentReference:
    'http://lifeomic.com/fhir/ocr/analyzed-resources/source-document-reference',
  /** The text (possibly edited) used to generate the resource*/
  sourceText:
    'http://lifeomic.com/fhir/ocr/analyzed-resources/comprehend-input-text',
  /** The location in the source document that contains the text used to derive the resource */
  sourceDocumentGeometry:
    'http://lifeomic.com/fhir/ocr/analyzed-resources/source-document-geometry',
  source: 'http://lifeomic.com/fhir/source',

  /** The page rotation that ingestion predicts page is rotated to */
  ingestionRotationPrediction: 'http://lifeomic.com/fhir/ocr/page-rotation',
  /** The page rotation that the user has rotated to*/
  manualPageRotation: 'http://lifeomic.com/fhir/ocr/manual-page-rotation',
  maskedWordIds: 'http://lifeomic.com/fhir/ocr/masked-word-ids',
  /** The width and height of a specific page */
  pageAspectRatio: 'http://lifeomic.com/fhir/ocr/page-aspect-ratio',
  /** When creating a custom classificaiton code, this is the system assigned */
  customClassificationCodeSystem:
    'http://lifeomic.com/fhir/ocr/custom-page-classification',
  /** Ties a created resource back to an analyze engine suggestion */
  sourceAnalyzeSuggestion:
    'http://lifeomic.com/fhir/ocr/source-analyze-suggestion',
  /** was this resource a manual selection */
  manualAnalyzeSelection:
    'http://lifeomic.com/fhir/ocr/manual-analyze-selection',
  /** Specifies what extractor the data came from */
  reportExtractorSourceId:
    'http://lifeomic.com/fhir/ocr/source-report-extractor',
  reportExtractorSource: 'LifeOmic Report Extractor',
  ocrAnalyzeSource: 'LifeOmic OCR Analyze',
  reviewStage: 'http://lifeomic.com/fhir/ocr/document-review-stage',
  customBodySite: 'http://lifeomic.com/fhir/ocr/custom-body-site',
  /**
   * DSTU3 does not support observation notes.  Observation notes are stored as an extension
   */
  observationNotes: 'http://lifeomic.com/fhir/ocr/observation-notes',
  /**
   * The external/customer ID for the document reference
   */
  externalIdentifier: 'http://lifeomic.com/fhir/ocr/external-document-id',
  /**
   * The page dates extracted during document ingestion
   */
  pageDates: 'http://lifeomic.com/fhir/ocr/page-dates',
  /**
   * The extractor who created this data
   */
  dataElementExtractor: 'http://lifeomic.com/fhir/ocr/data-element-extractor',
  /**
   * The email of the extractor who created this data,
   */
  dataElementExtractorEmail:
    'http://lifeomic.com/fhir/ocr/data-element-extractor-email',
  /**
   * The time the data was extracted
   */
  dataElementExtractionTime:
    'http://lifeomic.com/fhir/ocr/data-element-extraction-time',
  dataElementValidation: {
    validationTime: 'http://lifeomic.com/fhir/ocr/data-element-validation-time',
    validationUser: 'http://lifeomic.com/fhir/ocr/data-element-validator-user',
    validationUserEmail:
      'http://lifeomic.com/fhir/ocr/data-element-validator-email',
    validationStatus:
      'http://lifeomic.com/fhir/ocr/data-element-validation-status',
    validationComment:
      'http://lifeomic.com/fhir/ocr/data-element-validation-comment',
  },
  /** A extension url for composition acting as a flag that a date was manually assigned to the composition*/
  manualDateAssigned: 'http://lifeomic.com/fhir/ocr/manual-date-assigned',
  dataset: 'http://lifeomic.com/fhir/dataset',
  reportExtractorVerified:
    'http://lifeomic.com/fhir/ocr/verified-template-resource',
  pageImageId: 'http://lifeomic.com/fhir/ocr/page-image',
  /** The source page number */
  pageNumber: 'http://lifeomic.com/ocr/documents/page-number',
  /** Add review status to individual document sections */
  documentSectionReviewStage:
    'http://lifeomic.com/ocr/documents/document-section-review-stage',
};
