import React from 'react';
import { Modal } from '@lifeomic/chroma-react/components/Modal';
import { Pill } from '@lifeomic/chroma-react/components/Pill';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from '@lifeomic/chroma-react/styles';

const messages = defineMessages({
  products: {
    id: 'accountSelection.productsModal.products',
    defaultMessage: 'Products',
  },
});

const useStyles = makeStyles((theme) => ({
  pill: {
    marginBottom: theme.spacing(0.75),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
}));

export interface ProductsModalOwnProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  products: string[];
}

export type ProductsModalProps = ProductsModalOwnProps;

const ProductsModal: React.FC<ProductsModalProps> = ({ setIsOpen, isOpen, products }) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles({});

  return (
    <Modal
      isOpen={isOpen}
      title={formatMessage(messages.products)}
      onDismiss={() => setIsOpen(false)}
    >
      <Box fullWidth fullHeight className={classes.flexWrap} justify="center" align="center">
        {products &&
          products.map((product: string) => (
            <Pill color="primary" className={classes.pill} key={product} label={product} />
          ))}
      </Box>
    </Modal>
  );
};

export default React.memo(ProductsModal);
