import configActionTypes from './actionTypes';
import { window } from '../../../util/globals';
import { ConfigState } from './reducer';

export type Config = {
  apiBaseUri: string;
  oauthBaseUri: string;
  oauthClientId: string;
  useAPIDomains?: boolean;
  appsApiBaseUri?: string;
};

/**
 * Explicitely set config
 */
function setConfig(config: ConfigState) {
  return {
    type: configActionTypes.LOAD_CONFIG,
    config,
  };
}

/**
 * Load injected config
 */
function loadConfig() {
  return {
    type: configActionTypes.LOAD_CONFIG,
    config: (window.__INITIAL_STATE__ || {}).config || {},
  };
}

export default {
  loadConfig,
  setConfig,
};
