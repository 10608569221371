import urls from '../../../util/urls';
import types from './actionTypes';
import queryString from 'query-string';
import { getPersistedStore } from '../../middleware/persistMiddleware';
import { Dispatch } from 'redux';

function fetchProjects({ params, next }: { params?: any; next?: string } = {}) {
  return (dispatch: Dispatch) => {
    let nextUrl;
    if (next) {
      const parts = next.split('?');
      if (parts.length === 1) {
        return dispatch(
          fetchProjectsError(new Error('Invalid `next` parameter')),
        );
      }
      nextUrl = urls.api.projects.datasets({
        ...queryString.parse(parts.pop()),
        ...params,
      });
    }

    return dispatch({
      type: types.FETCH_PROJECTS,
      url: nextUrl || urls.api.projects.datasets(params),
    });
  };
}

function setActiveProject(id: string | null) {
  return {
    type: types.SET_ACTIVE_PROJECT,
    id,
  };
}

/**
 * Update the project picker to have any project other then the
 * given ID selected
 *
 * @param id the project to not be selected
 */
function unselectProject(id: string) {
  return {
    type: types.UNSELECT_PROJECT,
    id,
  };
}

function fetchProjectsError(error: any) {
  return {
    type: types.FETCH_PROJECTS_ERROR,
    error,
  };
}

interface HydratedActiveProject {
  activeProject?: string;
}

function hydrateActiveProject(projectId?: string) {
  const activeProject: HydratedActiveProject = projectId
    ? { activeProject: projectId }
    : getPersistedStore('projectPicker') || {};
  return {
    type: types.HYDRATE_ACTIVE_PROJECT,
    ...activeProject,
  };
}

export default {
  fetchProjects,
  fetchProjectsError,
  setActiveProject,
  hydrateActiveProject,
  unselectProject,
};
