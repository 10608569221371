import _get from 'lodash/get';
import _last from 'lodash/last';
import _merge from 'lodash/merge';
import _values from 'lodash/values';
import { MiddlewareAPI, Middleware, Action } from 'redux';
import { actionPrefixes, asyncActionSuffixes } from '../../util/constants';
import { buildCommonHeadersFromReduxStore } from '../../util/headers';
import {
  asyncRequestContextsActions,
  asyncRequestContextsSelectors,
} from '../../modules/asyncRequestContexts';
import { configSelectors } from '../../modules/config';
import { v4 as uuidv4 } from 'uuid';
import { ConfigState } from '../../modules/config/reducer';

const { requestStart, requestSuccess, requestError } =
  asyncRequestContextsActions;

const DEFAULT_FETCH_OPTS = {
  headers: { 'Content-Type': 'application/json' },
  method: 'GET',
};

function isLifeomicAsync(action: Action): action is AsyncRequestAction {
  const parts = action.type.split('/');
  return (
    parts.length > 1 &&
    parts[0] === actionPrefixes.LIFEOMIC &&
    parts[1] === actionPrefixes.ASYNC &&
    !_values(asyncActionSuffixes).includes(
      '_' + _last(action.type.split('_').filter((v: string) => v !== '$RESET')),
    )
  );
}

function parseResponses(data: any, responseContext: any): any | any[] {
  if (responseContext.parseResponseItems) {
    const responses = responseContext.parseResponseItems(data);
    if (responses) {
      return responses;
    }
  }

  return [];
}

function parseNextToken(data: any, responseContext: any): string {
  if (responseContext.parseNextToken) {
    return responseContext.parseNextToken(data);
  }

  return null;
}

function parseCount(data: any, responseContext: any): number {
  if (responseContext.parseCount) {
    return responseContext.parseCount(data);
  }

  return null;
}

function isRequestStillActive(
  store: MiddlewareAPI,
  requestKey: string,
  responseContextKey: string,
) {
  const { contextActiveRequestSelector } =
    asyncRequestContextsSelectors.bindContextKeyToSelectors(responseContextKey);
  const activeRequestKey = contextActiveRequestSelector(store.getState());
  return requestKey === activeRequestKey;
}

function handleContextError(
  fetchError: any,
  store: MiddlewareAPI,
  requestKey: string,
  action: any,
  status?: number,
  data?: any,
) {
  const responseContextKey = _get(action.responseContext, 'contextKey');

  if (
    responseContextKey &&
    isRequestStillActive(store, requestKey, responseContextKey)
  ) {
    store.dispatch(
      requestError(responseContextKey, fetchError, action.meta, status, data),
    );
  }
}

const buildRequestUrl = (path: string, appConfig: ConfigState) => {
  if (!appConfig?.useAPIDomains) return path;

  const testAPIPath = (pathComponent: string) =>
    path.indexOf(`/${pathComponent}`) === 0;

  const buildFullUrlFromAPIUrl = (apiUrl: string) => {
    if (!apiUrl) {
      console.error(
        `PWT is configured to use full urls, but no API domain is configured for request URL: ${path}`,
      );
      return path;
    }

    const indexOfSecondPathPart = path.indexOf('/', 1);
    const withoutAPIPathPart = path.substring(indexOfSecondPathPart);
    return `${apiUrl}${withoutAPIPathPart}`;
  };

  // by default, the first chunk of the path determines the base uri to reroute to
  if (testAPIPath('api')) {
    return buildFullUrlFromAPIUrl(appConfig.apiBaseUri);
  } else if (testAPIPath('auth')) {
    return buildFullUrlFromAPIUrl(appConfig.oauthBaseUri);
  } else {
    console.error(
      `PWT is configured to use full domains, but no api domain was configured for request url: ${path}`,
    );
    return path;
  }
};

export interface AsyncRequestMiddlewareOptions {
  onStatusCode?: {
    [key: number]: (store: MiddlewareAPI) => void;
  };
}

export interface AsyncRequestMiddlewareAction {
  type: string;
  meta: any;
  response?: any;
}
export interface AsyncRequestMiddlewareErrorAction
  extends AsyncRequestMiddlewareAction {
  error: Error;
  status?: number;
}

export interface AsyncRequestAction {
  type: string;
  url: string;
  excludeAuth?: boolean;
  failure?(error: any): AsyncRequestMiddlewareErrorAction;
  fetchOptions?: RequestInit;
  meta?: any;
  onFailure?(store: MiddlewareAPI, data: any): void;
  onSuccess?(store: MiddlewareAPI): void;
  responseContext?: any;
  start?(): AsyncRequestMiddlewareAction;
  success?(data: any): AsyncRequestMiddlewareAction;
  validateResponse?(response: any, data: any): boolean;
  account?: string;
}

function createAsyncRequestMiddleware(
  options: AsyncRequestMiddlewareOptions,
): Middleware {
  const { onStatusCode } = options;
  return store => next => async action => {
    if (isLifeomicAsync(action)) {
      const isResetAction = action.type.split('_').pop() === '$RESET';
      if (isResetAction) return next(action);

      const startAction = action.start
        ? action.start()
        : {
            type: action.type + asyncActionSuffixes.start,
            meta: action.meta,
          };

      const requestKey = uuidv4();
      const responseContextKey = _get(action.responseContext, 'contextKey');
      if (responseContextKey) {
        store.dispatch(
          requestStart(responseContextKey, requestKey, action.meta),
        );
      } else {
        store.dispatch(startAction);
      }

      const headers = buildCommonHeadersFromReduxStore(store);
      if (action.account) {
        headers['LifeOmic-Account'] = action.account;
      }
      if (action.excludeAuth) {
        delete headers['Authorization'];
      }

      const options = _merge(
        {},
        DEFAULT_FETCH_OPTS,
        { headers },
        action.fetchOptions || {},
      );

      try {
        const config = configSelectors.selectConfigState(store.getState());
        const url = buildRequestUrl(action.url, config);
        const response = await fetch(url, options);

        if (
          onStatusCode &&
          Object.prototype.hasOwnProperty.call(onStatusCode, response.status) &&
          typeof onStatusCode[response.status] === 'function'
        ) {
          onStatusCode[response.status](store);
        }

        const data = await response.text().then(text => {
          if (text) {
            try {
              return JSON.parse(text);
            } catch (err) {
              return text;
            }
          }
          return null;
        });

        if (
          action.validateResponse &&
          typeof action.validateResponse === 'function' &&
          !action.validateResponse(response, data)
        ) {
          const error = new Error(
            `Error fetching: Custom response validation failure - ${response.url} - ${data}`,
          );
          handleContextError(
            error,
            store,
            requestKey,
            action,
            response.status,
            data,
          );
          action.onFailure && action.onFailure(store, data);
          const failureAction = action.failure
            ? action.failure(error)
            : {
                error,
                type: action.type + asyncActionSuffixes.failure,
                meta: action.meta,
                status: response.status,
              };
          return next(failureAction);
        }

        if (!response.ok || response.status >= 400) {
          const error = new Error(
            `Error fetching ${response.url} - ${JSON.stringify(data)}`,
          );
          handleContextError(
            error,
            store,
            requestKey,
            action,
            response.status,
            data,
          );
          action.onFailure && action.onFailure(store, data);
          const failureAction = action.failure
            ? action.failure(error)
            : {
                error,
                type: action.type + asyncActionSuffixes.failure,
                response: data,
                meta: action.meta,
                status: response.status,
              };
          return next(failureAction);
        }

        action.onSuccess && action.onSuccess(store);

        if (
          responseContextKey &&
          isRequestStillActive(store, requestKey, responseContextKey)
        ) {
          const responses = parseResponses(data, action.responseContext);
          const nextToken = parseNextToken(data, action.responseContext);
          const count = parseCount(data, action.responseContext);
          const contextSuccessAction = requestSuccess(
            responseContextKey,
            data,
            responses,
            nextToken,
            action.meta,
            count,
          );
          return next(contextSuccessAction);
        } else {
          const successAction = action.success
            ? action.success(data)
            : {
                type: action.type + asyncActionSuffixes.success,
                response: data,
                meta: action.meta,
              };
          return next(successAction);
        }
      } catch (error) {
        handleContextError(error, store, requestKey, action);
        action.onFailure && action.onFailure(store, null);
        const failureAction = action.failure
          ? action.failure(error)
          : {
              error,
              type: action.type + asyncActionSuffixes.failure,
              meta: action.meta,
            };
        return next(failureAction);
      }
    }
    return next(action);
  };
}

export { createAsyncRequestMiddleware, isLifeomicAsync };
