import userActionTypes from './actionTypes';
import { createReducer } from '../../util/createReducer';
import { User } from '../../../common/types/Users';

export interface UserState {
  isFetching: boolean;
  error: any;
  response?: User | null;
  lastFetchAttempt: Date | null;
}

const INITIAL_STATE: UserState = {
  isFetching: false,
  error: null,
  response: null,
  lastFetchAttempt: null,
};

const reducer = createReducer(INITIAL_STATE, {
  [userActionTypes.FETCH_USER_START]: state => {
    return {
      ...state,
      isFetching: true,
      error: null,
    };
  },
  [userActionTypes.FETCH_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: false,
      response: action.response,
      lastFetchAttempt: new Date(),
    };
  },
  [userActionTypes.FETCH_USER_ERROR]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: action.error,
      response: null,
      lastFetchAttempt: new Date(),
    };
  },
  [userActionTypes.UPDATE_USER_START]: state => {
    return {
      ...state,
      isFetching: true,
      error: null,
    };
  },
  [userActionTypes.UPDATE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: false,
      response: action.response,
      lastFetchAttempt: new Date(),
    };
  },
  [userActionTypes.UPDATE_USER_ERROR]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: action.error,
      response: null,
      lastFetchAttempt: new Date(),
    };
  },
  [userActionTypes.DELETE_USER_START]: state => {
    return {
      ...state,
      isFetching: true,
      error: null,
    };
  },
  [userActionTypes.DELETE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: false,
      response: action.response,
      lastFetchAttempt: new Date(),
    };
  },
  [userActionTypes.DELETE_USER_ERROR]: (state, action) => {
    return {
      ...state,
      isFetching: false,
      error: action.error,
      response: null,
      lastFetchAttempt: new Date(),
    };
  },
});

export default reducer;
