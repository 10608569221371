import appActionTypes from './actionTypes';
import actions from './actions';
import { createReducer } from '../../util/createReducer';

export interface ConfigState {
  apiBaseUri?: string;
  oauthBaseUri?: string;
  oauthClientId?: string;
  useAPIDomains?: boolean;
  appsApiBaseUri?: string;
  lifeAppletsUri?: string;
  [key: string]: any;
}

const INITIAL_STATE: ConfigState = {};

type LoadConfigAction = ReturnType<typeof actions.loadConfig>;

const reducer = createReducer<ConfigState>(INITIAL_STATE, {
  [appActionTypes.LOAD_CONFIG]: (state, action: LoadConfigAction) => {
    return { ...state, ...action.config };
  },
});

export default reducer;
