import urls from '../../../util/urls';
import featuresActionTypes from './actionTypes';
import { Dispatch } from 'redux';

export interface Feature {
  feature: string;
  value?: boolean;
}

export interface FeatureParams {
  project?: string;
  tag?: string;
}

function fetchFeatures(params: FeatureParams = { tag: 'phc-ui' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: featuresActionTypes.FETCH_FEATURES,
      url: urls.api.features(params),
    });
  };
}

function fetchUnauthFeatures() {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: featuresActionTypes.FETCH_UNAUTH_FEATURES,
      url: urls.api.publicFeatures({ tag: 'phc-ui' }),
    });
  };
}

function toggleFeature(feature: string, value?: boolean) {
  return {
    type: featuresActionTypes.TOGGLE_FEATURE,
    feature,
    value,
  };
}

function toggleFeatures(features: Feature[]) {
  return {
    type: featuresActionTypes.TOGGLE_FEATURES,
    features,
  };
}

export default {
  fetchFeatures,
  fetchUnauthFeatures,
  toggleFeature,
  toggleFeatures,
};
