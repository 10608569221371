import isNil from 'lodash/isNil';
import { window } from '../globals';
import { isLocalStorageEnabled } from '.';

export const AUTH_STORAGE_KEY = '@@lifeomic/store/auth';

export interface StoredAuth {
  isAuthenticated: boolean;
  isProcessing: boolean;
  expiresAt: number;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  jwtPayload: any;
  email: string;
  oauthClientId: string;
  error: any;
  errorDescription: string;
}

export type AuthStorageInvalidReasons =
  | 'noAuthStored'
  | 'authExpired'
  | 'notAuthenticated'
  | 'stillProcessing'
  | null;

export class GetAuthStorageResult {
  private _storedAuth: StoredAuth;
  constructor(storedAuth: StoredAuth) {
    this._storedAuth = storedAuth;
  }

  get storedAuth(): StoredAuth {
    return this._storedAuth;
  }

  get authStorageInvalidReason(): AuthStorageInvalidReasons {
    // check required props
    if (
      !this._storedAuth ||
      !this._storedAuth.accessToken ||
      !this._storedAuth.refreshToken ||
      !this._storedAuth.idToken ||
      isNil(this._storedAuth.expiresAt)
    ) {
      return 'noAuthStored';
    }

    if (!this._storedAuth.isAuthenticated) return 'notAuthenticated';

    if (this._storedAuth.isProcessing) return 'stillProcessing';

    if (new Date().getTime() > this._storedAuth.expiresAt) return 'authExpired';

    return null;
  }

  get isAuthValid(): boolean {
    return !this.authStorageInvalidReason;
  }
}

export function getAuthStorage(): GetAuthStorageResult {
  const locallyStoredJson = window.localStorage.getItem(AUTH_STORAGE_KEY);
  const parsedObj = locallyStoredJson ? JSON.parse(locallyStoredJson) : null;
  return new GetAuthStorageResult(parsedObj);
}

export function setAuthStorage(auth: StoredAuth) {
  if (!isLocalStorageEnabled()) {
    throw new Error('Local storage is not enabled and auth cannot be stored.');
  }

  window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
}

export function clearAuthStorage() {
  if (!isLocalStorageEnabled()) {
    throw new Error('Local storage is not enabled and auth cannot be cleared.');
  }

  window.localStorage.removeItem(AUTH_STORAGE_KEY);
}
