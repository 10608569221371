import { Dispatch } from 'redux';
import urls from '../../../util/urls';
import userActionTypes from './actionTypes';
import { User } from '../../../common/types/Users';

function deleteUser() {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: userActionTypes.DELETE_USER,
      url: urls.api.users.user(),
      fetchOptions: {
        method: 'DELETE',
      },
    });
  };
}

function fetchUser() {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: userActionTypes.FETCH_USER,
      url: urls.api.users.user(),
    });
  };
}

function updateUser(profile: Partial<User['profile']>) {
  return (dispatch: Dispatch) => {
    if (!profile) {
      return dispatch(updateUserError(new Error('Missing profile payload')));
    } else {
      return dispatch({
        type: userActionTypes.UPDATE_USER,
        url: urls.api.users.user(),
        fetchOptions: {
          method: 'PATCH',
          body: JSON.stringify({ profile }),
        },
      });
    }
  };
}

function updateUserError(error: any) {
  return {
    type: userActionTypes.UPDATE_USER_ERROR,
    error,
  };
}

export default {
  deleteUser,
  fetchUser,
  updateUser,
  updateUserError,
};
