import _get from 'lodash/get';
import { MiddlewareAPI } from 'redux';

interface StringMap {
  [key: string]: string;
}

export function buildCommonHeadersFromReduxStore(
  reduxStore: MiddlewareAPI,
): StringMap {
  const state = reduxStore.getState();
  const activeAccount = _get(state, 'account.activeAccount', null);
  const accessToken = _get(state, 'auth.accessToken', null);
  const headers = {} as StringMap;
  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if (activeAccount) headers['LifeOmic-Account'] = activeAccount;
  return headers;
}
