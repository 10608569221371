import urls from '../../../util/urls';
import types from './actionTypes';
import queryString from 'query-string';
import { getPersistedStore } from '../../middleware/persistMiddleware';
import { Dispatch } from 'redux';

function fetchProjectCohorts({
  params,
  next,
}: { params?: any; next?: string } = {}) {
  return (dispatch: Dispatch) => {
    if (params?.projectId) {
      dispatch(setCohortListProject(params.projectId));
    }

    let nextUrl;
    if (next) {
      const parts = next.split('?');
      if (parts.length === 1) {
        return dispatch(
          fetchProjectCohortsError(new Error('Invalid `next` parameter')),
        );
      }
      nextUrl = urls.api.cohorts({
        ...queryString.parse(parts.pop()),
        ...params,
      });
    }

    return dispatch({
      type: types.FETCH_PROJECT_COHORTS,
      url: nextUrl || urls.api.cohorts(params),
    });
  };
}

function setActiveProjectCohort(id: string | null) {
  return {
    type: types.SET_ACTIVE_PROJECT_COHORT,
    id,
  };
}

function setCohortListProject(cohortListProject: string) {
  return {
    type: types.SET_COHORT_LIST_PROJECT,
    cohortListProject,
  };
}

function fetchProjectCohortsError(error: any) {
  return {
    type: types.FETCH_PROJECT_COHORTS_ERROR,
    error,
  };
}

interface HydratedActiveProjectCohort {
  activeProjectCohort?: string;
  lastUsedCohortByProject?: Record<string, string>;
}

function hydrateActiveProjectCohort(cohortId?: string) {
  const persisted = getPersistedStore('projectCohortPicker');
  const activeCohortState: HydratedActiveProjectCohort = cohortId
    ? { ...persisted, activeProjectCohort: cohortId }
    : persisted || {};
  return {
    type: types.HYDRATE_ACTIVE_PROJECT_COHORT,
    ...activeCohortState,
  };
}

export default {
  fetchProjectCohorts,
  setActiveProjectCohort,
  hydrateActiveProjectCohort,
  setCohortListProject,
};
