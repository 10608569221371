import { createAsyncActionTypes } from '../../util/createActionTypes';

const asyncActions = createAsyncActionTypes([
  'DELETE_USER',
  'DELETE_USER_START',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_ERROR',
  'FETCH_USER',
  'UPDATE_USER',
  'FETCH_USER_START',
  'FETCH_USER_SUCCESS',
  'FETCH_USER_ERROR',
  'UPDATE_USER_START',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_ERROR',
]);

export default {
  ...asyncActions,
};
