import * as React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import ErrorBoundary from './ErrorBoundary';

const RootErrorBoundary: React.FC = ({ children }) => {
  if (process.env.DISABLE_BUGSNAG_REPORTING) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  try {
    const apiKey = (window as any).__INITIAL_STATE__.config.bugsnagKey;
    const releaseStage = (window as any).__INITIAL_STATE__.config.releaseStage;
    const beforeSend = (report: any) => {
      report.breadcrumbs = report.breadcrumbs.map((b) => {
        if ((b.metaData || {}).targetText && (b.metaData || {}).targetText !== '(...)') {
          b.metaData.targetText = '[REDACTED]';
        }
        return b;
      });
    };
    const client = bugsnag({ apiKey, releaseStage, beforeSend }).use(bugsnagReact, React);
    const BugsnagBoundary = client.getPlugin('react');

    return <BugsnagBoundary>{children}</BugsnagBoundary>;
  } catch (e) {
    console.warn('Error boundary not loaded', e);

    return <>{children}</>;
  }
};

export default RootErrorBoundary;
