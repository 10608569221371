import notificationsActions, {
  NotificationOptions,
} from '../modules/notifications/actions';
import { Store } from 'redux';

class NotificationHelper {
  private successMessage: string | null;
  private failureMessage: string;
  private options: NotificationOptions;

  constructor(
    successMessage?: string | null,
    failureMessage?: string,
    options?: NotificationOptions,
  ) {
    this.successMessage = successMessage;
    this.failureMessage = failureMessage;
    this.options = options;
  }

  getAsyncRequestSuccessHandler() {
    if (!this.successMessage) return null;

    const successOptions: NotificationOptions = {
      ...this.options,
      statusType: 'success',
    };

    return (store: Store) => {
      store.dispatch(
        notificationsActions.addNotification(
          this.successMessage || '',
          successOptions,
        ),
      );
    };
  }

  getAsyncRequestFailureHandler() {
    if (!this.failureMessage) return null;

    const failureOptions: NotificationOptions = {
      ...this.options,
      statusType: 'error',
    };

    return (store: Store) => {
      store.dispatch(
        notificationsActions.addNotification(
          this.failureMessage,
          failureOptions,
        ),
      );
    };
  }

  getAsyncRequestHandlers() {
    const onSuccess = this.getAsyncRequestSuccessHandler();
    const onFailure = this.getAsyncRequestFailureHandler();

    return Object.assign(
      {},
      onSuccess && { onSuccess },
      onFailure && { onFailure },
    );
  }
}

export default NotificationHelper;
