/**
 * Util functions to interact with window.  Pulled into a separate function to make
 * mocking in tests easier 🙃
 */

/**
 * A "vanity url" is a style of url where the account would be listed as the first sub domain. For example, instead of `wellness.us.lifeomic.com`, it would be `account.wellness.us.lifeomic.com`.
 *
 * This function really just gets the first subdomain from the url, then we later check to see if it's one of the expected subdomains (like `wellness`) and if not, we assume it's an account from a vanity url.
 */
export const getAccountFromVanityUrl = () =>
  typeof window !== 'undefined' && window.location
    ? window.location.host?.split('.')?.[0]
    : null;

export const getWindowLocationSearch = () =>
  typeof window !== 'undefined' && window.location
    ? window.location.search
    : null;
