import { AuthState } from './reducer';

export const selectAuthState = (state: any): AuthState => state.auth;

export const isAuthenticated = (state: any) => {
  const expiresAt = selectAuthState(state).expiresAt;
  return new Date().getTime() < expiresAt;
};

export const selectAccessToken = (state: any) =>
  selectAuthState(state).accessToken;

export const selectOauthClientId = (state: any) =>
  selectAuthState(state).oauthClientId;

export const selectOauth = (state: any) => selectAuthState(state).oauth;

export const selectRefreshToken = (state: any) =>
  selectAuthState(state).refreshToken;

export const selectIdToken = (state: any) => selectAuthState(state).idToken;

export const selectError = (state: any) => selectAuthState(state).error || {};

export const selectCookieState = (state: any) =>
  selectAuthState(state).cookieState || {};
