import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountSelectors,
  accountActions,
} from '@lifeomic/phc-web-toolkit/dist/redux/modules/account';
import { appActions, appSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/app';
import { userPatientsSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/userPatients';
import AccountSelection from './Pages/AccountSelection';
import urls from '../../../util/urls';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { useCustomCompareEffect } from 'react-use';
import _isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import BrandedBackground from '../../common/BrandedBackground';
import Loading from '../Loading';

type Tag = {
  system: string;
  code: string | number;
};

type QueryParams = {
  account: string;
  projectId?: string;
};

const projectFromPatient = (patient: fhir.Patient) => {
  const tags = get(patient, 'meta.tag', []);
  const datasetTag =
    tags.find((tag: Tag) => tag?.system === 'http://lifeomic.com/fhir/dataset') || {};
  return datasetTag?.code;
};

const Switcher: React.FC = () => {
  const [selectedAccountId, setSelectedAccountId] = React.useState<string | undefined>(undefined);
  const accounts = useSelector(accountSelectors.selectAccounts);
  const dispatch = useDispatch();
  const userPatients = useSelector(userPatientsSelectors.selectPatientsFromResponse);
  const appInitializing = useSelector(appSelectors.selectInitializing);
  const filteredAccounts = accounts.filter((account) => account.id !== 'lifeomiclife');

  // This is used when an account is selected, the access token needs to be refresh, and the app needs
  //     to be reinitialized in order to see if the user is a patient in any projects in the selected account
  const setSelectedAccountIdReduxRefresh = React.useCallback(
    (id: string) => {
      dispatch(accountActions.setActiveAccount(id));
      dispatch(appActions.initializeApp({ skipCookieProcessing: true }));
      setSelectedAccountId(id);
    },
    [dispatch],
  );

  // This useEffect handles the case where the user has no accounts
  React.useEffect(() => {
    // If the user has no accounts, navigate straight to the PHC
    if (!appInitializing && !accounts.length) {
      window.location.href = urls.externalApps.PHC({});
    }
  }, [appInitializing, accounts]);

  // This useCustomCompareEffect handles the cases when the user has only one account
  useCustomCompareEffect(
    () => {
      // If filteredAccounts has a length of 1, that one account is not lifeomiclife,
      //     otherwise, it is lifeomiclife
      if (filteredAccounts && filteredAccounts.length === 1) {
        setSelectedAccountIdReduxRefresh(filteredAccounts[0].id);
      } else if (accounts && accounts.length === 1) {
        setSelectedAccountIdReduxRefresh('lifeomiclife');
      }
    },
    [accounts, filteredAccounts],
    ([oldAccounts, oldFilteredAccount], [newAccounts, newFilteredAccounts]) =>
      _isEqual(oldAccounts, newAccounts) && _isEqual(oldFilteredAccount, newFilteredAccounts),
  );

  // This useCustomCompareEffect waits for the app to be reinitialized so that userPatients is updated
  //     and navigates the user to the correct app
  useCustomCompareEffect(
    () => {
      if (selectedAccountId && !appInitializing) {
        const products = accounts.filter((account) => account.id === selectedAccountId)[0]
          ?.products;
        if (selectedAccountId === 'lifeomiclife') {
          // If lifeomiclife is the only account for the user, go to PHC-Me
          window.location.href = urls.externalApps.me({ account: selectedAccountId });
        } else if (products?.includes('PrecisionOCR') && !products.includes('PHC')) {
          // If PrecisionOCR is a product on the selected account and PHC is not, go to PrecisionOCR
          window.location.href = urls.externalApps.precisionOCR({ account: selectedAccountId });
        } else if (userPatients && userPatients.length > 0) {
          // If the user is a patient in a project in a the specified account, go to PHC-Me
          const params: QueryParams = { account: selectedAccountId };
          if (userPatients.length === 1) {
            // The project is specified if the user is a patient in one project in the account
            const projectId = projectFromPatient(userPatients[0]);
            params.projectId = projectId;
          }
          window.location.href = urls.externalApps.me(params);
        } else {
          // PHC is the default app to navigate to
          window.location.href = urls.externalApps.PHC({ account: selectedAccountId });
        }
      }
    },
    [appInitializing, selectedAccountId, userPatients, accounts],
    ([oldAppInitializing, oldSelectedAccountId], [newAppInitializing, newSelectedAccountId]) =>
      oldAppInitializing === newAppInitializing && oldSelectedAccountId === newSelectedAccountId,
  );

  return (
    <BrandedBackground>
      <Box justify="center" align="center" fullWidth fullHeight>
        {!selectedAccountId && filteredAccounts.length ? (
          <AccountSelection
            accounts={filteredAccounts}
            setSelectedAccountId={setSelectedAccountIdReduxRefresh}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </BrandedBackground>
  );
};

export default React.memo(Switcher);
