import {
  createAsyncActionTypes,
  createLifeomicActionTypes,
} from '../../util/createActionTypes';

const asyncActions = createAsyncActionTypes([
  'FETCH_USER_PATIENTS',
  'FETCH_USER_PATIENTS_START',
  'FETCH_USER_PATIENTS_SUCCESS',
  'FETCH_USER_PATIENTS_ERROR',
]);

export default {
  ...asyncActions,
  ...createLifeomicActionTypes(['EXPLICITELY_ASSIGN_USER_PATIENT']),
};
