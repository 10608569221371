import { combineReducers, ReducersMapObject } from 'redux';
import { accountReducer as account } from './modules/account';
import { appReducer as app } from './modules/app';
import { asyncRequestContextsReducer as asyncRequestContexts } from './modules/asyncRequestContexts';
import { authReducer as auth, authActionTypes } from './modules/auth';
import { configReducer as config } from './modules/config';
import { evaluatedPolicyReducer as evaluatedPolicy } from './modules/evaluatedPolicy';
import { featuresReducer as features } from './modules/features';
import { notificationsReducer as notifications } from './modules/notifications';
import { projectPickerReducer as projectPicker } from './modules/projectPicker';
import { projectCohortPickerReducer as projectCohortPicker } from './modules/projectCohortPicker';
import { userReducer as user } from './modules/user';
import { userPatientsReducer as userPatients } from './modules/userPatients';
import { maskedModeReducer as maskedMode } from './modules/maskedMode';
import { ocrViewerReducer as ocrViewer } from '../components/OCR/common/Viewer/redux';
import { groupReducer as groups } from './modules/group';
import { dialogReducer as dialog } from './modules/dialog';
import { consentReducer as consent } from './modules/consent';

function createRootReducer(additionalReducers: ReducersMapObject<any> = {}) {
  const allReducers = {
    account,
    app,
    asyncRequestContexts,
    auth,
    config,
    consent,
    dialog,
    evaluatedPolicy,
    features,
    groups,
    notifications,
    projectPicker,
    projectCohortPicker,
    user,
    userPatients,
    maskedMode,
    ocrViewer,
    ...additionalReducers,
  };
  const appReducer = combineReducers(allReducers);

  // Logging out resets the state
  const rootReducer = (state: any, action: any) => {
    if (action.type === authActionTypes.INITIATE_LOGOUT) {
      state = undefined;
    }
    return appReducer(state, action);
  };

  return rootReducer;
}

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
