import { Fields } from '../../components/PatientDataCollection/PatientDataCollection';

export enum MrnConfigType {
  typeCoding = 'typeCoding',
  system = 'system',
}

export interface MrnConfig {
  configType: MrnConfigType;
  system: string;
  code?: string;
}

export type SupportedIntlPhoneNumberValidation = 'us' | 'none';

export interface EngagementConfig {
  demographics: {
    useDemographics?: boolean;
    intlPhoneNumberFormat?: SupportedIntlPhoneNumberValidation;
    hiddenFields?: Fields[];
    requiredFields?: Fields[];
  };
}

export interface Project {
  id: string;
  name?: string;
  genomeBuild?: string;
  description?: string;
  lrn?: string;
  deletionDate?: string;
  owner?: string;
  auditLogging: boolean;
  shortName?: string;
  mrnConfig?: MrnConfig;
  engagementConfig?: EngagementConfig;
  part11?: boolean;
}
