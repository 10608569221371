import { actionPrefixes } from './constants';

export type ActionMap<T extends string> = Record<T, string>;

function createActionTypes<T extends string>(
  actionTypes: T[],
  prefixes: string[] = [],
): ActionMap<T> {
  return actionTypes.reduce((prev, cur: string) => {
    return { ...(prev as any), [cur]: [...prefixes, cur].join('/') };
  }, {} as ActionMap<T>);
}

function createLifeomicActionTypes<T extends string>(
  actionTypes: T[],
  prefixes: string[] = [],
) {
  return createActionTypes(actionTypes, [actionPrefixes.LIFEOMIC, ...prefixes]);
}

function createAsyncActionTypes<T extends string>(
  actionTypes: T[],
  prefixes: string[] = [],
) {
  return createActionTypes(actionTypes, [
    actionPrefixes.LIFEOMIC,
    actionPrefixes.ASYNC,
    ...prefixes,
  ]);
}

export { createActionTypes, createAsyncActionTypes, createLifeomicActionTypes };
